import React from 'react';
import { useNavigate } from 'react-router-dom';

import { capitalize, cleanValue, innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { Chip, IconButton, Line, Link, Switch, Tooltip, Type, useConfirm, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { add, OnesyDate } from '@onesy/date';
import { Booking } from '@onesy/api';

import IconMaterialGroups from '@onesy/icons-material-rounded-react/IconMaterialGroupsW100';
import IconMaterialHangoutMeeting from '@onesy/icons-material-rounded-react/IconMaterialHangoutMeetingW100';
import IconMaterialTrendingUp from '@onesy/icons-material-rounded-react/IconMaterialTrendingUpW100';
import IconMaterialNoteStackW100Rounded from '@onesy/icons-material-rounded-react/IconMaterialNoteStackW100';
import IconMaterialWallet from '@onesy/icons-material-rounded-react/IconMaterialWalletW100';
import IconMaterialSmartDisplay from '@onesy/icons-material-rounded-react/IconMaterialSmartDisplayW100';
import IconMaterialEventNote from '@onesy/icons-material-rounded-react/IconMaterialEventNoteW100';
import IconMaterialDeleteW100Rounded from '@onesy/icons-material-rounded-react/IconMaterialDeleteW100';

import { AutoCompleteObjects, CalendarMenu, DurationForm, Input, Inputs, MediaForm, ModalForm, NoResults, NumericTextField, PayForm, QuantityForm, Repeat, Select, SelectAColor, SmartTextField, TextField } from 'ui';
import { AppService, BookingService, CustomerGroupService, CustomerService, LocationService, MeetingService, OrganizationService, PropertyService, RoomService, UserGroupService } from 'services';
import { getDate, getErrorMessage, optionsBookingIntegrations, optionsBookingStatusUpdate } from 'utils';
import { IQuerySubscription } from 'types';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const navigate = useNavigate();
  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const queryProperties = useSubscription<IQuerySubscription>(PropertyService.queryObjects);

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState('update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const properties = React.useMemo(() => {
    const values = { general: [], client: [] };

    queryProperties?.response?.forEach(item => values[item.for].push({
      id: item.id,
      name: item.name,
      for: item.for,
      type: item.type,

      ...(item.for === 'client' ? { clients: {} } : { value: '' })
    }));

    return values;
  }, [queryProperties?.response]);

  const propertiesDefault = React.useMemo(() => {
    const values = object?.properties || { general: [], client: [] };

    ['general', 'client'].forEach(itemFor => {
      properties[itemFor].forEach(item => {
        if (!values[itemFor].find(itemExists => itemExists.id === item.id)) {
          values[item.for].push(item);
        }
      });
    });

    return values;
  }, [properties, object]);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'status': {
        name: 'Status',
        value: object?.status,
        is: 'string'
      },
      'starts_at': {
        name: 'Starts at',
        value: object?.starts_at || OnesyDate.milliseconds,
        is: 'number',
        required: true
      },
      'ends_at': {
        name: 'Ends at',
        value: object?.ends_at,
        is: 'number'
      },
      'customers': {
        name: 'Clients',
        is: 'array',
        of: 'object',
        value: object?.customers || []
      },
      'customer_groups': {
        name: 'Client groups',
        is: 'array',
        of: 'object',
        value: object?.customer_groups || []
      },
      'employees': {
        name: 'Trainers',
        is: 'array',
        of: 'object',
        value: object?.employees || []
      },
      'employee_groups': {
        name: 'Trainer groups',
        is: 'array',
        of: 'object',
        value: object?.employee_groups || []
      },
      'repeat': {
        name: 'Repeat',
        is: 'object',
        value: object?.repeat || {}
      },
      'reminders': {
        name: 'Reminders',
        value: object?.reminders || [],
        is: 'array',
        of: 'object'
      },
      'color': {
        name: 'Color',
        value: object?.color || 'lightblue',
        is: 'string'
      },
      'spaces': {
        name: 'Spaces',
        value: object?.spaces || {
          value: 0,
          unlimited: false
        },
        is: 'object'
      },
      'locations': {
        name: 'Locations',
        is: 'array',
        of: 'object',
        value: object?.locations || []
      },
      'rooms': {
        name: 'Rooms',
        is: 'array',
        of: 'object',
        value: object?.rooms || []
      },
      'pay_override': {
        name: 'Pay override',
        value: object?.pay_override,
        is: 'boolean'
      },
      'pay': {
        name: 'Pay',
        value: object?.pay || {},
        is: 'object'
      },
      'duration': {
        name: 'Duration',
        value: object?.duration || {
          value: 1,
          unit: 'hour'
        },
        is: 'object'
      },
      'cancellation_policy': {
        name: 'Cancellation policy',
        value: object?.cancellation_policy || {
          value: 1,
          unit: 'day'
        },
        is: 'object'
      },
      'online_booking': {
        name: 'Online booking',
        value: object?.online_booking,
        is: 'boolean'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      },
      'room_shared': {
        name: 'Room shared',
        value: object?.room_shared,
        is: 'boolean'
      },
      'notes_public': {
        name: 'Notes public',
        value: object?.notes_public,
        max: 14e4,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 140.000 characters'
        }
      },
      'notes_private': {
        name: 'Notes private',
        value: object?.notes_private,
        max: 14e4,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 140.000 characters'
        }
      },
      'properties': {
        name: 'Properties',
        value: propertiesDefault
      },
      'integrations': {
        name: 'Integrations',
        value: object?.integrations,
        is: 'array',
        of: 'string'
      },
      'meeting': {
        name: 'Meeting',
        value: object?.meeting,
        is: 'object'
      }
    },

    valueDefault: {
      ...object,

      properties: propertiesDefault
    }
  });

  console.log(1, form, object);

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const init = React.useCallback(() => {
    PropertyService.queryObjects.value!.query({
      query: {
        query: {
          model: 'bookings'
        }
      }
    });
  }, []);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  React.useEffect(() => {
    if (queryProperties.length) refs.form.current.onChange('properties', propertiesDefault);
  }, [propertiesDefault]);

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    } as Booking;

    if (is('array', body.customers)) body.customers = body.customers.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.customer_groups)) body.customer_groups = body.customer_groups.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.employees)) body.employees = body.employees.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.employee_groups)) body.employee_groups = body.employee_groups.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.locations)) body.locations = body.locations.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.rooms)) body.rooms = body.rooms.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (body.meeting?.id) body.meeting = {
      id: body.meeting.id,
      name: body.meeting.name
    };

    if (!body.status || body.status === 'booked') delete body.status;

    // ends_at 
    // starts_at + duration 
    if (body.starts_at && body.duration) body.ends_at = add(body.duration.value, body.duration.unit as any, new OnesyDate(body.starts_at)).milliseconds;

    const result = await BookingService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Booking updated`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onRemove = React.useCallback(async (object: any) => {
    const confirmed = await confirm.open({
      name: `Removing a booking will remove all related information to this booking, payments, expenses etc. It can't be undone.`
    });

    if (!confirmed) return;

    const result = await BookingService.remove(object.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Booking removed`
      });
    }

    // close 
    onClose();

    // refresh 
    BookingService.refetch();
  }, []);

  const onOpen = React.useCallback(page => {
    navigate(page);

    onClose();
  }, []);

  const toProperties = React.useCallback(() => {
    navigate('/properties/add');

    onClose();
  }, []);

  const onUpdatePropertyInput = React.useCallback((item: any, valueNew: any, client?: any) => {
    const propertiesNew = { ...refs.form.current.value.properties };

    const index = propertiesNew[item.for].findIndex(itemProperty => itemProperty.id === item.id);

    if (client) propertiesNew[item.for][index].clients[client.id] = valueNew;
    else propertiesNew[item.for][index].value = valueNew;

    refs.form.current.onChange('properties', propertiesNew);
  }, []);

  const getPropertyInput = React.useCallback((item: any, client?: any) => {
    const property = refs.form.current.value.properties?.[item.for]?.find(itemForm => itemForm.id === item.id);

    const otherProps: any = {
      key: item.id,
      name: textToInnerHTML(item.name),
      valueDefault: property?.value || property?.clients?.[client?.id],
      onChange: (valueNew: any) => onUpdatePropertyInput(item, valueNew, client),
      version: 'outlined',
      size: 'small',
      fullWidth: true
    };

    const type = item.type;

    if (type === 'number') return (
      <NumericTextField
        {...otherProps}
      />
    );

    return (
      <TextField
        {...otherProps}
      />
    );
  }, [queryProperties?.response]);

  const clients = React.useMemo(() => {
    const values = [];
    const added = {};

    object.customers?.forEach(item => {
      if (!added[item.id]) {
        values.push(item);

        added[item.id] = true;
      }
    });

    object.customer_groups?.forEach(itemGroup => {
      itemGroup?.customers?.forEach(item => {
        if (!added[item.id]) {
          values.push(item);

          added[item.id] = true;
        }
      });
    });

    return values;
  }, [object]);

  const getWeekday = React.useCallback(item => {
    const mapWeekday = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday'
    };

    return mapWeekday[item];
  }, []);

  const errors = Object.keys(form.values).map(item => form.values[item].error).filter(Boolean).join(', ');

  const inputProps: any = {
    gap: 1,
    gapMain: 1
  };

  const modal: any = {
    Info: <>
      <Inputs>
        {object.status !== 'booked' ? <>
          <Input
            name='Booking status'

            {...inputProps}
          >
            <Type
              version='b1'

              color={object.status === 'postponed' ? 'warning' : object.status === 'cancelled' ? 'error' : 'inherit'}
            >
              {cleanValue(object.status, { capitalize: true })}
            </Type>
          </Input>

          {object?.type === 'class' && (
            <Input
              name='Spaces'

              {...inputProps}
            >
              <Type
                version='b1'
              >
                {object.spaces.unlimited ? 'Unlimited' : object.spaces.value} spaces
              </Type>
            </Input>
          )}

          {!!object.locations?.length && (
            <Input
              name='Location'

              {...inputProps}
            >
              <Type
                version='b1'
              >
                {object.locations?.map(item => item.name).join(', ')}
              </Type>
            </Input>
          )}

          {!!object.rooms?.length && (
            <Input
              name='Room'

              {...inputProps}

              description={object.room_shared ? 'Room is shared' : ''}
            >
              <Type
                version='b1'
              >
                {object.rooms?.map(item => item.name).join(', ')}
              </Type>
            </Input>
          )}

          {object.cancellation_policy && (
            <Input
              name='Cancellation policy'

              description='How long until it can be canceled'

              {...inputProps}
            >
              <Type
                version='b1'
              >
                {object.cancellation_policy.value} {object.cancellation_policy.unit}{object.cancellation_policy.value === 1 ? '' : 's'}
              </Type>
            </Input>
          )}

          {object.repeat.active && (
            <Input
              name='Booking repeat'

              description='When does booking occur'

              {...inputProps}
            >
              <Type
                version='b1'
              >
                Every {object.repeat.value} {object.repeat.unit}{object.repeat.value === 1 ? '' : 's'}{object.repeat.weekdays?.length ? ` on ${object.repeat.weekdays.map(item => getWeekday(item)).join(', ')}` : ''}
              </Type>
            </Input>
          )}

          {object.meeting?.id && (
            <Input
              name={`${textToInnerHTML(object.meeting.name)} meeting`}

              {...inputProps}
            >
              <Chip
                onClick={() => onOpen(`/meetings/${object.meeting?.id}`)}

                start={<IconMaterialHangoutMeeting />}

                size='small'
              >
                Open meeting
              </Chip>
            </Input>
          )}
        </> : <>
          {object.status === 'booked' && (
            <Input
              name='Booking status'

              description={`You can complete booking if it's done, or postpone, cancel it.`}
            >
              <Select
                value={form.value.status}

                onChange={(valueNew: any) => form.onChange('status', valueNew)}

                options={optionsBookingStatusUpdate}

                fullWidth
              />
            </Input>
          )}

          {object?.type === 'class' && (
            <QuantityForm
              description='How many spaces does the class have'

              form={form}

              noUnit
            />
          )}

          <Input
            name='Locations'
          >
            <AutoCompleteObjects
              name='Locations'

              value={form.values.locations.value}

              onChange={(valueNew: any) => form.onChange('locations', valueNew)}

              onAdd={(valueNew: any) => form.onChange('locations', [...(form.value.locations || []), valueNew])}

              service={LocationService}

              query={{
                apps: ['personal-trainer']
              }}

              addProps={{
                apps: ['personal-trainer']
              }}

              multiple

              fullWidth

              add
            />
          </Input>

          <Input
            name='Rooms'
          >
            <AutoCompleteObjects
              name='Rooms'

              value={form.values.rooms.value}

              onChange={(valueNew: any) => form.onChange('rooms', valueNew)}

              onAdd={(valueNew: any) => form.onChange('rooms', [...(form.value.rooms || []), valueNew])}

              service={RoomService}

              query={{
                apps: ['personal-trainer']
              }}

              addProps={{
                apps: ['personal-trainer']
              }}

              multiple

              fullWidth

              add
            />

            <Input
              startName={(
                <Switch
                  value={form.values['room_shared'].value}

                  onChange={(valueNew: any) => form.onChange('room_shared', valueNew)}

                  size='small'
                />
              )}

              name='Shared room'

              description={`Check if room ${object?.type} is in, is a shared room.`}

              size='small'
            />
          </Input>

          <Input
            name='Cancellation policy'

            description='How long until it can be canceled'

            endHeader={(
              <Line
                gap={1}

                direction='row'

                wrap='wrap'

                align='center'

                justify={{
                  default: 'flex-start',
                  700: 'flex-end'
                }}

                fullWidth={{
                  default: false,
                  700: true
                }}

                flexNo
              >
                <DurationForm
                  name=''

                  form={form}

                  property='cancellation_policy'

                  fullWidth={false}
                />
              </Line>
            )}
          />

          <Repeat
            form={form}

            description='Repeat the booking'

            fullWidth
          />

          <Input
            name='Meeting'

            description='Add a meeting reference to this booking'
          >
            <AutoCompleteObjects
              name='Meeting'

              value={form.values.meeting.value}

              valueInputDefault={textToInnerHTML(form.values.meeting.value?.name)}

              onChange={(valueNew: any) => form.onChange('meeting', valueNew)}

              service={MeetingService}

              fullWidth
            />
          </Input>

          <Input
            name='Integrations'

            description='Note: At the moment integration is one way only. That means we will create for example a Google Calendar event on booking add, but if you update booking date/time afterwards, you will have to manually update your Google Calendar event for it.  You have to have integration connected in organization settings for this to work. This update only applies for repeating bookings.'
          >
            <Select
              name='Integrations'

              value={form.value.integrations}

              onChange={(valueNew: any) => form.onChange('integrations', valueNew)}

              options={optionsBookingIntegrations}

              multiple

              clear

              fullWidth
            />
          </Input>

          <Input
            startName={(
              <Switch
                value={form.values['online_booking'].value}

                onChange={(valueNew: any) => form.onChange('online_booking', valueNew)}
              />
            )}

            name='Online booking'

            description='Check if you want this class to show on the website as available for users to choose for booking online'
          />
        </>}
      </Inputs>
    </>,

    Participants: <>
      <Inputs>
        {form.value.status === 'booked' ? <>
          <Input
            name='Clients'
          >
            <AutoCompleteObjects
              name='Clients'

              value={form.values.customers.value}

              onChange={(valueNew: any) => form.onChange('customers', valueNew)}

              service={CustomerService}

              multiple

              fullWidth
            />
          </Input>

          <Input
            name='Client groups'
          >
            <AutoCompleteObjects
              name='Client groups'

              value={form.values.customer_groups.value}

              onChange={(valueNew: any) => form.onChange('customer_groups', valueNew)}

              service={CustomerGroupService}

              multiple

              fullWidth
            />
          </Input>

          <Input
            name='Trainers'
          >
            <AutoCompleteObjects
              name='Trainers'

              value={form.values.employees.value}

              onChange={(valueNew: any) => form.onChange('employees', valueNew)}

              service={OrganizationService}

              method='queryUsersPost'

              multiple

              fullWidth
            />
          </Input>

          <Input
            name='Trainer groups'
          >
            <AutoCompleteObjects
              name='Trainer groups'

              value={form.values.employee_groups.value}

              onChange={(valueNew: any) => form.onChange('employee_groups', valueNew)}

              service={UserGroupService}

              multiple

              fullWidth
            />
          </Input>
        </> : <>

          {!!form.value?.customers?.length && (
            <Input
              gap={1}

              name='Clients'
            >
              <Type
                version='b1'
              >
                {form.value.customers.map(item => textToInnerHTML(item.name)).join(', ')}
              </Type>
            </Input>
          )}

          {!!form.value?.customer_groups?.length && (
            <Input
              gap={1}

              name='Client groups'
            >
              <Type
                version='b1'
              >
                {form.value.customer_groups.map(item => textToInnerHTML(item.name)).join(', ')}
              </Type>
            </Input>
          )}

          {!!form.value?.employees?.length && (
            <Input
              gap={1}

              name='Trainers'
            >
              <Type
                version='b1'
              >
                {form.value.employees.map(item => textToInnerHTML(item.name)).join(', ')}
              </Type>
            </Input>
          )}

          {!!form.value?.employee_groups?.length && (
            <Input
              gap={1}

              name='Trainer groups'
            >
              <Type
                version='b1'
              >
                {form.value.employee_groups.map(item => textToInnerHTML(item.name)).join(', ')}
              </Type>
            </Input>
          )}
        </>}
      </Inputs>
    </>,

    Finances: <>
      <Inputs>
        <Input
          startName={(
            <Switch
              value={form.values['pay_override'].value}

              onChange={(valueNew: any) => form.onChange('pay_override', valueNew)}
            />
          )}

          name='Pay override'

          description='If selected this will be used as the payment details for the trainers, for this booking, instead their individual payment details are used.'
        />

        {form.value.pay_override && (
          <PayForm
            name='Booking trainer payment override'

            description={`If you want to set custom payment for this ${object?.type} specifically, for all trainers. It will override each trainer's individual payment details.`}

            form={form}
          />
        )}
      </Inputs>
    </>,

    About: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={3}

            maxRows={4}

            multiline

            fullWidth
          />
        </Input>

        <MediaForm
          type='image'

          name='Image'

          form={form}
        />

        <MediaForm
          type='audio'

          name='Audio'

          form={form}
        />

        <MediaForm
          type='video'

          name='Video'

          form={form}
        />
      </Inputs>
    </>,

    Progress: <>
      {!queryProperties?.length && (
        <NoResults
          size='small'
        >
          <Type
            version='b1'
          >
            No custom properties found. You can <Link version='b1' onClick={toProperties}>add new properties</Link>.
          </Type>
        </NoResults>
      )}

      {!!queryProperties?.length && (
        <Line
          gap={3}

          fullWidth
        >
          {!!properties?.general?.length && (
            <Inputs
              gap={2}

              name='General info'

              description='Booking general info'
            >
              {properties.general.map(item => getPropertyInput(item))}
            </Inputs>
          )}

          {!!properties?.client?.length && (
            <Inputs
              gap={1.5}

              name='Client info'

              description='Info per client'
            >
              {!clients?.length && (
                <NoResults
                  size='small'
                >
                  No clients, client groups found in this booking.
                </NoResults>
              )}

              {clients.map(itemClient => (
                <Input
                  key={itemClient.id}

                  gap={1.5}

                  name={textToInnerHTML(itemClient.name)}
                >
                  {properties.client.map(item => getPropertyInput(item, itemClient))}
                </Input>
              ))}
            </Inputs>
          )}
        </Line>
      )}
    </>,

    Notes: <>
      <Inputs>
        <Input
          name='Notes private'

          description='Only visible to employees'
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.notes_private.value || '')}

            onChange={(valueNew: string) => form.onChange('notes_private', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline
          />
        </Input>

        <Input
          name='Notes public'

          description='Visible to everyone '
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.notes_public.value || '')}

            onChange={(valueNew: string) => form.onChange('notes_public', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Booking'

      tab={tab}

      tabs={[
        { name: 'Info', Icon: IconMaterialEventNote },
        { name: 'Participants', Icon: IconMaterialGroups },

        ...(object.status === 'booked' ? [{ name: 'Finances', Icon: IconMaterialWallet }] : []),

        { name: 'About', Icon: IconMaterialSmartDisplay },
        { name: 'Progress', Icon: IconMaterialTrendingUp },
        { name: 'Notes', Icon: IconMaterialNoteStackW100Rounded }
      ]}

      onChangeTab={onChangeTab}

      endHeaderLeft={mode === 'update' ? <></> : capitalize(object?.type || 'session')}

      startHeaderRight={mode === 'update' ? <>
        <Input
          name='Starts at'

          gap={0}

          TypeProps={{
            version: 't3'
          }}

          size='small'
        >
          <CalendarMenu
            value={form.value}

            onChange={form.onChange}

            dateProperty='starts_at'

            closeOnChange={false}

            time

            noRepeat

            noRemove

            style={{
              marginLeft: -8,
              width: 'auto'
            }}
          />
        </Input>

        <DurationForm
          form={form}
        />
      </> : <>
        <Type
          version='b2'
        >
          {getDate(form.value.starts_at, 'entire')}
        </Type>

        <Input
          name='Duration'

          gap={0}

          TypeProps={{
            version: 't3'
          }}

          size='small'
        >
          <Type
            version='b2'
          >
            {!!Object.keys(form.value.duration || {}).length ? `${form.value.duration?.value} ${form.value.duration?.unit}` : 'No duration'}
          </Type>
        </Input>
      </>}

      footerLeftEnd={form.value.meeting?.id && <>
        {form.value.meeting?.id && (
          <Chip
            onClick={() => onOpen(`/meetings/${form.value.meeting?.id}`)}

            start={<IconMaterialHangoutMeeting />}

            size='small'
          >
            Open meeting
          </Chip>
        )}
      </>}

      footerRightStart={<>
        {errors && (
          <Type
            color='error'
          >
            {errors}
          </Type>
        )}

        <Tooltip
          name='Remove'
        >
          <IconButton
            onClick={() => onRemove(object)}
          >
            <IconMaterialDeleteW100Rounded />
          </IconButton>
        </Tooltip>

        {mode === 'update' && (
          <SelectAColor
            value={form.value.color}

            onChange={(valueNew: any) => form.onChange('color', valueNew)}
          />
        )}
      </>}

      object={object}

      add

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
