import React from 'react';

import { capitalize, innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Tooltip, useForm, useSnackbars } from '@onesy/ui-react';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialMenuBook from '@onesy/icons-material-rounded-react/IconMaterialMenuBookW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { Color, Input, Inputs, MediaForm, ModalForm, Select, SelectAColor, SmartTextField, TextField } from 'ui';
import { AppService, RecipeService } from 'services';
import { getErrorMessage, optionsRecipesDifficulty } from 'utils';
import Recipe from 'pages/main/NutritionPlans/Recipes/Recipe';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: textToInnerHTML(object?.name),
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'time': {
        name: 'Prepare time',
        value: object?.time,
        is: 'string'
      },
      'difficulty': {
        name: 'Difficulty',
        value: object?.difficulty || 'medium',
        is: 'string'
      },
      'serving': {
        name: 'Serving',
        value: object?.serving,
        is: 'string'
      },
      'ingredients': {
        name: 'Ingredients',
        value: object?.ingredients ? Object.keys(object.ingredients).map(item => ({
          name: item,
          items: object.ingredients[item]
        })) : [{ name: 'default', items: [] }]
      },
      'instructions': {
        name: 'Instructions',
        value: object?.instructions ? Object.keys(object.instructions).map(item => ({
          name: item,
          items: object.instructions[item]
        })) : [{ name: 'default', items: [] }]
      },
      'additional': {
        name: 'Additional',
        value: object?.additional ? Object.keys(object.additional).map(item => ({
          name: item,
          items: object.additional[item]
        })) : [{ name: 'default', items: [] }]
      },
      'color': {
        name: 'Color',
        value: object?.color,
        is: 'string'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    const ingredients = {};

    body.ingredients.forEach(item => {
      ingredients[item.name] = item.items;
    });

    body.ingredients = ingredients;

    const instructions = {};

    body.instructions.forEach(item => {
      instructions[item.name] = item.items;
    });

    body.instructions = instructions;

    const additional = {};

    body.additional.forEach(item => {
      additional[item.name] = item.items;
    });

    body.additional = additional;

    const result = !object?.id ? await RecipeService.add(body) : await RecipeService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Recipe ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onAddGroup = React.useCallback((type: string) => {
    const object = [...refs.form.current.values[type].value];

    object.push({
      name: 'New group',
      items: []
    });

    refs.form.current.onChange(type, object);
  }, []);

  const onRemoveGroup = React.useCallback((type: string, index: number) => {
    const object = [...refs.form.current.values[type].value];

    object.splice(index, 1);

    refs.form.current.onChange(type, object);
  }, []);

  const onAddGroupItem = React.useCallback((type: string, index: number) => {
    const object = [...refs.form.current.values[type].value];

    object[index].items.push('');

    refs.form.current.onChange(type, object);
  }, []);

  const onRemoveGroupItem = React.useCallback((type: string, index: number, indexItem: number) => {
    const object = [...refs.form.current.values[type].value];

    object[index].items.splice(indexItem, 1);

    refs.form.current.onChange(type, object);
  }, []);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const getGroup = type => {

    return (
      <Input
        gap={1.5}

        name={capitalize(type)}

        weight={500}

        size='large'

        endName={(
          <Tooltip
            name={`Add ${type} group`}
          >
            <IconButton
              onClick={() => onAddGroup(type)}
            >
              <IconMaterialAdd
                size='large'
              />
            </IconButton>
          </Tooltip>
        )}
      >
        {form.values[type].value.map((group, index: number) => (
          <Input
            key={index}

            name={group.name === 'default' ? 'Main' : (
              <SmartTextField
                valueDefault={textToInnerHTML(group.name)}

                onChange={(valueNew: any) => form.onChange(type, textToInnerHTML(valueNew), `${index}.name`)}

                additional={{
                  version: 't1',
                  weight: 400
                }}
              />
            )}

            weight={400}

            endName={[
              <Tooltip
                name={`Add ${type} group item`}
              >
                <IconButton
                  onClick={() => onAddGroupItem(type, index)}
                >
                  <IconMaterialAdd
                    size='regular'
                  />
                </IconButton>
              </Tooltip>,
              <Tooltip
                name={`Remove ${type} group`}
              >
                <IconButton
                  onClick={() => onRemoveGroup(type, index)}
                >
                  <IconMaterialRemove
                    size='regular'
                  />
                </IconButton>
              </Tooltip>
            ]}
          >
            {group.items.map((item, indexItem: number) => (
              <Input
                name={`Item ${indexItem + 1}`}

                gap={0.5}

                endName={(
                  <Tooltip
                    name={`Remove ${type} group item`}
                  >
                    <IconButton
                      onClick={() => onRemoveGroupItem(type, index, indexItem)}
                    >
                      <IconMaterialRemove
                        size='regular'
                      />
                    </IconButton>
                  </Tooltip>
                )}

                size='small'
              >
                <TextField
                  key={index + indexItem}

                  placeholder='Description'

                  value={textToInnerHTML(item || '')}

                  onChange={(valueNew: any) => form.onChange(type, innerHTMLToText(valueNew), `${index}.items.${indexItem}`)}

                  minRows={3}

                  maxRows={7}

                  multiline

                  fullWidth
                />
              </Input>
            ))}
          </Input>
        ))}
      </Input>
    );
  };

  const modal: any = {
    read: <>
      <Recipe
        object={{
          ...object,

          ...form.value
        }}
      />
    </>,

    Info: <>
      <Inputs>
        <Input
          name='Prepare time'

          description='How longs does it take in total to prepare this recipe'
        >
          <TextField
            placeholder='Time'

            valueDefault={form.values['time'].value}

            error={form.values['time'].error}

            helperText={form.values['time'].error}

            onChange={(valueNew: any) => form.onChange('time', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Difficulty'
        >
          <Select
            value={form.value.difficulty}

            error={form.values['difficulty'].error}

            helperText={form.values['difficulty'].error}

            onChange={(valueNew: any) => form.onChange('difficulty', valueNew)}

            options={optionsRecipesDifficulty}

            fullWidth
          />
        </Input>

        <Input
          name='Serving'

          description='How many servings are made from this recipe'
        >
          <TextField
            placeholder='Serving'

            valueDefault={form.values['serving'].value}

            error={form.values['serving'].error}

            helperText={form.values['serving'].error}

            onChange={(valueNew: any) => form.onChange('serving', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        {getGroup('ingredients')}

        {getGroup('instructions')}

        {getGroup('additional')}
      </Inputs>
    </>,

    About: <>
      <Input
        name='Name'
      >
        <TextField
          placeholder='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />
      </Input>

      <Input
        name='Description'
      >
        <TextField
          placeholder='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={3}

          maxRows={4}

          multiline

          fullWidth
        />
      </Input>

      <MediaForm
        type='image'

        name='Image'

        form={form}
      />

      <MediaForm
        type='audio'

        name='Audio'

        form={form}
      />

      <MediaForm
        type='video'

        name='Video'

        form={form}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={mode === 'update' ? [
        { name: 'Info', Icon: IconMaterialMenuBook },
        { name: 'About', Icon: IconMaterialInfo }
      ] : undefined}

      onChangeTab={onChangeTab}

      footerRightStart={mode === 'update' ? <>
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      </> : (
        <Color
          color={form.value.color}
        />
      )}

      object={object}

      add={!object}

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      noWrite={object?.onesy}

      TabsProps={{
        justify: 'center'
      }}
    >
      {mode === 'read' ? modal.read : modal[tab]}
    </ModalForm>
  );
});

export default Element;
