import React from 'react';

import { Chip, Chips, Line, Medias, Properties, Type } from '@onesy/ui-react';
import { classNames } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IExercise } from '@onesy/api';
import { capitalize, is, stringToColor, textToInnerHTML } from '@onesy/utils';

import { Input } from 'ui';

export interface IExerciseElement extends IBaseElement {
  object: IExercise;
}

const Element: React.FC<IExerciseElement> = React.forwardRef((props, ref: any) => {
  const {
    object,

    className,

    ...other
  } = props;

  const noValue = 'Not provided';

  const primary_muscles = (object.primary_muscles || []).map(item => capitalize(item)).join(', ');

  const secondary_muscles = (object.secondary_muscles || []).map(item => capitalize(item)).join(', ');

  const force = capitalize(object.force);

  const level = capitalize(object.level);

  const mechanic = capitalize(object.mechanic);

  const equipment = (is('string', object.equipment) ? capitalize(object.equipment as any) : (object.equipment || []).map(item => capitalize(item)).join(', '));

  return (
    <Line
      ref={ref}

      gap={4}

      flex

      fullWidth

      className={classNames([
        'onesy-Exercise',

        className
      ])}

      {...other}
    >
      <Line
        gap={2.5}

        fullWidth
      >
        {!!object.media?.length && (
          <Medias
            align='center'

            values={object.media?.map((item: any) => ({
              value: (item?.id || item?.url || item?.urlEmbed) ? item : undefined
            }))}

            size='large'

            noName
          />
        )}

        <Properties
          version='row'

          rowGap={0.5}

          values={[
            {
              name: 'Primary muscles',
              value: primary_muscles || noValue,
              props: {
                ValueProps: {
                  priority: !primary_muscles ? 'secondary' : undefined
                }
              }
            },
            {
              name: 'Secondary muscles',
              value: secondary_muscles || noValue,
              props: {
                ValueProps: {
                  priority: !secondary_muscles ? 'secondary' : undefined
                }
              }
            },
            {
              name: 'Force',
              value: force || noValue,
              props: {
                ValueProps: {
                  priority: !force ? 'secondary' : undefined
                }
              }
            },
            {
              name: 'Level',
              value: level || noValue,
              props: {
                ValueProps: {
                  priority: !level ? 'secondary' : undefined
                }
              }
            },
            {
              name: 'Mechanic',
              value: mechanic || noValue,
              props: {
                ValueProps: {
                  priority: !mechanic ? 'secondary' : undefined
                }
              }
            },
            {
              name: 'Equipment',
              value: equipment || noValue,
              props: {
                ValueProps: {
                  priority: !equipment ? 'secondary' : undefined
                }
              }
            },
            {
              name: 'Categories', value: object.categories?.length ? (
                <Chips
                  size='small'

                  style={{
                    width: 'auto'
                  }}
                >
                  {object.categories?.map(item => (
                    <Chip
                      key={item.id}

                      color={stringToColor(item.name)}

                      style={{
                        cursor: 'default'
                      }}
                    >
                      {item.name}
                    </Chip>
                  ))}
                </Chips>
              ) : noValue,
              props: {
                ValueProps: {
                  priority: !object.categories?.length ? 'secondary' : undefined
                }
              }
            }
          ]}

          size='large'
        />
      </Line>

      <Input
        gap={1.5}

        name='Instructions'

        size='large'
      >
        <Line
          gap={1}

          Component='ol'

          style={{
            listStylePosition: 'inside'
          }}
        >
          {object.instructions?.map((item, index) => (
            <li
              key={index}
            >
              <Type
                version='b1'

                whiteSpace='pre-wrap'

                dangerouslySetInnerHTML={{
                  __html: textToInnerHTML(item)
                }}

                style={{
                  display: 'inline'
                }}
              />
            </li>
          ))}
        </Line>
      </Input>
    </Line>
  );
});

export default Element;
