import React from 'react';
import { useNavigate } from 'react-router-dom';

import { is } from '@onesy/utils';
import { Expand, Line, Tab, Tabs, Type, useLocation, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import IconMaterialDraw from '@onesy/icons-material-rounded-react/IconMaterialDrawW100';
import IconMaterialDrawCollage from '@onesy/icons-material-rounded-react/IconMaterialDrawCollageW100';
import IconMaterialShortText from '@onesy/icons-material-rounded-react/IconMaterialShortTextW100';
import IconMaterialStarRate from '@onesy/icons-material-rounded-react/IconMaterialStarRateW100';
import IconMaterialCircle from '@onesy/icons-material-rounded-react/IconMaterialCircleW100Filled';
import IconMaterialCalculate from '@onesy/icons-material-rounded-react/IconMaterialCalculateW100';
import IconMaterialContract from '@onesy/icons-material-rounded-react/IconMaterialContractW100';
import IconMaterialCreditCard from '@onesy/icons-material-rounded-react/IconMaterialCreditCardW100';
import IconMaterialGroups2 from '@onesy/icons-material-rounded-react/IconMaterialGroups2W100';
import IconMaterialCategory from '@onesy/icons-material-rounded-react/IconMaterialCategoryW100';
import IconMaterialBedroomChild from '@onesy/icons-material-rounded-react/IconMaterialBedroomChildW100';
import IconMaterialHomeWork from '@onesy/icons-material-rounded-react/IconMaterialHomeWorkW100';
import IconMaterialArticle from '@onesy/icons-material-rounded-react/IconMaterialArticleW100';
import IconMaterialAnalytics from '@onesy/icons-material-rounded-react/IconMaterialAnalyticsW100';
import IconMaterialReceiptLong from '@onesy/icons-material-rounded-react/IconMaterialReceiptLongW100';
import IconMaterialReceipt from '@onesy/icons-material-rounded-react/IconMaterialReceiptW100';
import IconMaterialNutrition from '@onesy/icons-material-rounded-react/IconMaterialNutritionW100';
import IconMaterialMenuBook from '@onesy/icons-material-rounded-react/IconMaterialMenuBookW100';
import IconMaterialSubscriptions from '@onesy/icons-material-rounded-react/IconMaterialSubscriptionsW100';
import IconMaterialDirectionsRun from '@onesy/icons-material-rounded-react/IconMaterialDirectionsRunW100';
import IconMaterialCalendarClock from '@onesy/icons-material-rounded-react/IconMaterialCalendarClockW100';
import IconMaterialSell from '@onesy/icons-material-rounded-react/IconMaterialSellW100';
import IconMaterialCardMembership from '@onesy/icons-material-rounded-react/IconMaterialCardMembershipW100';
import IconMaterialPackage2 from '@onesy/icons-material-rounded-react/IconMaterialPackage2W100';
import IconMaterialToday from '@onesy/icons-material-rounded-react/IconMaterialTodayW100';
import IconMaterialListAlt from '@onesy/icons-material-rounded-react/IconMaterialListAltW100';
import IconMaterialGroup from '@onesy/icons-material-rounded-react/IconMaterialGroupW100';
import IconMaterialSmartDisplay from '@onesy/icons-material-rounded-react/IconMaterialSmartDisplayW100';
import IconMaterialGrocery from '@onesy/icons-material-rounded-react/IconMaterialGroceryW100';
import IconMaterialCalendarMonth from '@onesy/icons-material-rounded-react/IconMaterialCalendarMonthW100';
import IconMaterialDeployedCode from '@onesy/icons-material-rounded-react/IconMaterialDeployedCodeW100';
import IconMaterialOpenInNew from '@onesy/icons-material-rounded-react/IconMaterialOpenInNewW100';
import IconMaterialExercise from '@onesy/icons-material-rounded-react/IconMaterialExerciseW100';
import IconMaterialChatBubble from '@onesy/icons-material-rounded-react/IconMaterialChatBubbleW100';
import IconMaterialFinanceMode from '@onesy/icons-material-rounded-react/IconMaterialFinanceModeW100';
import IconMaterialPersonBook from '@onesy/icons-material-rounded-react/IconMaterialPersonBookW100';
import IconMaterialPerson from '@onesy/icons-material-rounded-react/IconMaterialPersonFilledW100';
import IconMaterialCalendarToday from '@onesy/icons-material-rounded-react/IconMaterialCalendarTodayW100';
import IconMaterialInsertChart from '@onesy/icons-material-rounded-react/IconMaterialInsertChartW100';
import IconMaterialNotifications from '@onesy/icons-material-rounded-react/IconMaterialNotificationsW100';
import IconMaterialLock from '@onesy/icons-material-rounded-react/IconMaterialLockW100';
import IconMaterialFileUpload from '@onesy/icons-material-rounded-react/IconMaterialFileUploadW100';
import IconMaterialImagesmode from '@onesy/icons-material-rounded-react/IconMaterialImagesmodeW100';
import IconMaterialAccountCircle from '@onesy/icons-material-rounded-react/IconMaterialAccountCircleW100';
import IconMaterialIntegrationInstructions from '@onesy/icons-material-rounded-react/IconMaterialIntegrationInstructionsW100';
import IconMaterialAccountBalanceWallet from '@onesy/icons-material-rounded-react/IconMaterialAccountBalanceWalletW100';
import IconMaterialStore from '@onesy/icons-material-rounded-react/IconMaterialStoreW100';

import { ReactComponent as Logo } from 'assets/svg/logos/logo-personal-trainer.svg';
import { ReactComponent as LogoBoard } from 'assets/svg/logos/logo-board.svg';
import { ReactComponent as LogoMeeting } from 'assets/svg/logos/logo-meeting.svg';
import { ReactComponent as LogoWebsite } from 'assets/svg/logos/logo-website.svg';

import { NotificationsMenu, UserMenu } from 'ui';
import { AuthService, StorageService } from 'services';
import { getRootPage, isHex } from 'utils';
import { ISignedIn } from 'types';
import config from 'config';

const useStyle = style(theme => ({
  root: {
    flex: '0 0 auto',
    zIndex: 14
  },

  header: {
    position: 'relative',
    padding: 16,
    flex: '0 0 auto'
  },

  logo: {
    '& > svg': {
      height: 44,
      width: 'auto',
      cursor: 'pointer',
      userSelect: 'none',
      transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

      '&:active': {
        transform: 'scale(0.94)'
      }
    }
  },

  tabsMain: {
    '&.onesy-Tabs-root': {
      width: 'auto',
      flex: '1 1 auto'
    }
  },

  tabs: {
    '& .onesy-Tabs-line': {
      height: 2
    }
  },

  tab: {
    '&.onesy-Tab-root': {
      padding: '8px 20px'
    }
  },

  logoItem: {
    height: 34,
    width: 'auto'
  }
}), { name: 'onesy-Header' });

const Header = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [page, setPage] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [subRoutes, setSubRoutes] = React.useState([]);

  const refs = {
    root: React.useRef<any>(undefined)
  };

  const user = signedIn?.user;

  const isUser = user?.is?.user;
  const isAdmin = user?.is?.admin;

  const itemLogoProps: any = {
    className: classes.logoItem
  };

  const routesMain = React.useMemo(() => {

    return [
      { name: 'Overview', to: '/', Icon: IconMaterialInsertChart },
      { name: 'Calendar', to: '/calendar', Icon: IconMaterialCalendarToday },
      { name: 'Clients', to: '/clients', Icon: IconMaterialPersonBook },

      ...(isAdmin ? [{ name: 'Employees', to: '/employees', Icon: IconMaterialPerson }] : []),

      { name: 'Services', to: '/sessions', Icon: IconMaterialDeployedCode },
      { name: 'Workouts', to: '/workout-plans', Icon: IconMaterialExercise },
      { name: 'Nutrition', to: '/nutrition-plans', Icon: IconMaterialGrocery },

      ...(isAdmin ? [{ name: 'Finances', to: '/finances', Icon: IconMaterialFinanceMode }] : []),

      { name: 'Chat', to: '/chats', Icon: IconMaterialChatBubble },
      { name: 'Reviews', to: '/reviews', Icon: IconMaterialStarRate },

      ...(isAdmin ? [{ name: 'Reports', to: '/reports', Icon: IconMaterialContract }] : []),

      { name: 'Meetings', to: '/meetings', start: <LogoMeeting {...itemLogoProps} /> },
      { name: 'Boards', to: '/boards', start: <LogoBoard {...itemLogoProps} /> },

      { name: 'Websites', link: `${config.value.apps.app.url}/websites?onesy_token=${StorageService.get('token')}`, start: <LogoWebsite {...itemLogoProps} />, IconEnd: IconMaterialOpenInNew }
    ];
  }, [signedIn]);

  const routes = React.useMemo(() => {

    return {
      calendar: isUser ? [
        { name: 'Bookings', to: '/calendar', Icon: IconMaterialCalendarMonth },
        ...(isAdmin ? [{ name: 'Booking requests', to: '/calendar/booking-requests', Icon: IconMaterialCalendarClock }] : []),
        { name: 'Availability', to: '/calendar/available', Icon: IconMaterialCircle, propsIcon: { color: 'success', size: 15 } }
      ] : [],

      clients: isUser ? [
        { name: 'Manage', to: '/clients', Icon: IconMaterialPersonBook },
        { name: 'Forms', to: '/forms', Icon: IconMaterialListAlt },
        { name: 'Client groups', to: '/client-groups', Icon: IconMaterialGroup }
      ] : [],

      employees: isAdmin ? [
        { name: 'Manage', to: '/employees', Icon: IconMaterialPerson },
        { name: 'Employee groups', to: '/employee-groups', Icon: IconMaterialGroup }
      ] : [],

      services: isUser ? [
        { name: 'Sessions', to: '/sessions', Icon: IconMaterialToday },
        { name: 'Classes', to: '/classes', Icon: IconMaterialGroups2 },
        { name: 'Packages', to: '/packages', Icon: IconMaterialPackage2 },
        { name: 'Memberships', to: '/memberships', Icon: IconMaterialCardMembership },
        { name: 'Discounts', to: '/discounts', Icon: IconMaterialSell },
        { name: 'Properties', to: '/properties', Icon: IconMaterialShortText },
        { name: 'Locations', to: '/locations', Icon: IconMaterialHomeWork },
        { name: 'Rooms', to: '/rooms', Icon: IconMaterialBedroomChild },
        { name: 'Categories', to: '/categories', Icon: IconMaterialCategory }
      ] : [],

      workouts: isUser ? [
        { name: 'Exercises', to: '/exercises', Icon: IconMaterialDirectionsRun },
        { name: 'Workouts', to: '/workouts', Icon: IconMaterialSmartDisplay },
        { name: 'Workout plans', to: '/workout-plans', Icon: IconMaterialSubscriptions }
      ] : [],

      nutrition: isUser ? [
        { name: 'Ingredients', to: '/ingredients', Icon: IconMaterialNutrition },
        { name: 'Calories calculator', to: '/calories-calculator', Icon: IconMaterialCalculate },
        { name: 'Recipes', to: '/recipes', Icon: IconMaterialMenuBook },
        { name: 'Nutrition plans', to: '/nutrition-plans', Icon: IconMaterialGrocery }
      ] : [],

      finances: isAdmin ? [
        { name: 'Overview', to: '/finances', Icon: IconMaterialAnalytics },
        { name: 'All transactions', to: '/transactions', Icon: IconMaterialReceipt },
        { name: 'Payments', to: '/payments', Icon: IconMaterialCreditCard },
        { name: 'Expenses', to: '/expenses', Icon: IconMaterialArticle },
        { name: 'Charges', to: '/charges', Icon: IconMaterialContract },
        { name: 'Balances', to: '/balances', Icon: IconMaterialAccountBalanceWallet },
        { name: 'Invoices', to: '/invoices', Icon: IconMaterialReceiptLong }
      ] : [],

      library: [
        { name: 'Library', to: '/library', Icon: IconMaterialImagesmode },
        { name: 'Upload', to: '/library/upload', Icon: IconMaterialFileUpload }
      ],

      settings: [
        { name: 'Profile', to: '/settings', Icon: IconMaterialAccountCircle },
        { name: 'Security', to: '/settings/security', Icon: IconMaterialLock },
        { name: 'Notifications', to: '/settings/notifications', Icon: IconMaterialNotifications }
      ],

      organization_settings: isAdmin ? [
        { name: 'Organization', to: '/organization/settings/info', Icon: IconMaterialStore },
        { name: 'Subscription', to: '/organization/settings/subscription', Icon: IconMaterialAccountBalanceWallet },
        { name: 'Integrations', to: '/organization/settings/integrations', Icon: IconMaterialIntegrationInstructions }
      ] : [],

      boards: [
        { name: 'My boards', to: '/boards', Icon: IconMaterialDraw },
        { name: 'Shared boards', to: '/boards/shared', Icon: IconMaterialDrawCollage }
      ]
    };
  }, [signedIn]);

  const routesMap = React.useMemo(() => {
    const map = {};

    Object.keys(routes).forEach(key => {
      map[key.replace('/', '')] = key;

      routes[key].forEach(item => map[item.to.replace('/', '')] = key);
    });

    return map;
  }, [routes]);

  React.useEffect(() => {
    const pathname = location.pathname;

    let page_ = pathname.split('/').filter(Boolean)[0];

    if (pathname.startsWith('/organization/settings')) page_ = 'organization_settings';

    const pageRoute = routesMap[page_];

    const menus = routes[pageRoute];

    if (menus?.length) setSubRoutes(menus);

    setPage(page_);
  }, [routesMap, location]);

  const isActive = React.useCallback((pathname: string, route: string) => {
    const pageRoute = routesMap[page];

    const routeMain = routesMain.find(item => item.name.toLowerCase() === pageRoute);

    const end = pathname.replace(`${route}/`, '');

    return !!(
      routeMain?.to === route ||
      route === `/${pageRoute}` ||
      pathname === route ||
      (pathname.startsWith(route) && (end === 'add' || isHex(`0x${end}`)))
    );
  }, [page, routesMain]);

  const isActiveSubRoute = React.useCallback((pathname: string, route: string) => {
    const end = pathname.replace(`${route}/`, '');
    const endIsID = end.split('/').filter(Boolean).every(item => isHex(`0x${item}`));

    return !!(
      pathname === route ||
      (pathname.startsWith(route) && (end === 'add' || endIsID))
    );
  }, [page]);

  React.useEffect(() => {
    const pageRoute = routesMap[page];

    const menus = routes[pageRoute];

    setOpen(!!menus?.length);
  }, [page]);

  const iconProps = {
    size: 'large'
  };

  return (
    <Line
      ref={(item: any) => {
        refs.root.current = item;

        if (ref) {
          if (is('function', ref)) ref(item)
          else ref.current = item;
        }
      }}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {/* Header */}
      <Line
        gap={2}

        direction='row'

        justify='space-between'

        align='center'

        fullWidth

        className={classNames([
          classes.header
        ])}
      >
        <Line
          gap={2}

          direction='row'

          align='center'

          flex
        >
          <Line
            className={classes.logo}
          >
            <Logo
              onClick={() => navigate(getRootPage(signedIn))}
            />
          </Line>
        </Line>

        <Line
          gap={1.5}

          direction='row'

          align='center'
        >
          <NotificationsMenu />

          <UserMenu />
        </Line>
      </Line>

      <Line
        gap={0.5}

        direction='column'

        fullWidth
      >
        {/* Main menus */}
        <Line
          gap={0}

          direction='row'

          justify='unset'

          align='center'

          fullWidth
        >
          <Tabs
            value={location.pathname}

            isActive={isActive}

            size='large'

            noDivider

            className={classes.tabs}
          >
            {routesMain?.map((item: any) => (
              <Tab
                key={item.to}

                onClick={() => item.link ? window.open(item.link, 'blank') : navigate(item.to)}

                value={item.to}

                icon={item.start ? item.start : item.Icon ? <item.Icon {...iconProps} /> : null}

                className={classes.tab}
              >
                <Type
                  version='t1'
                >
                  {item.name}
                </Type>

                {item.IconEnd ? <item.IconEnd size='regular' {...item.propsIcon} /> : null}
              </Tab>
            ))}
          </Tabs>
        </Line>

        {/* Page menus */}
        <Expand
          in={open}
        >
          <Line
            gap={0}

            direction='row'

            justify='unset'

            align='center'

            fullWidth
          >
            <Tabs
              value={location.pathname}

              isActive={isActiveSubRoute}

              size='large'

              noDivider

              className={classes.tabs}
            >
              {subRoutes?.map((item: any) => (
                <Tab
                  key={item.to}

                  onClick={() => navigate(item.to)}

                  value={item.to}

                  icon={item.Icon ? <item.Icon {...iconProps} {...item.propsIcon} /> : null}

                  className={classes.tab}
                >
                  <Type
                    version='t1'
                  >
                    {item.name}
                  </Type>
                </Tab>
              ))}
            </Tabs>
          </Line>
        </Expand>
      </Line>
    </Line>
  );
});

export default Header;
