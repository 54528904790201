import React from 'react';

import { stringToColor, textToInnerHTML } from '@onesy/utils';
import { Line, ListItem, Medias, Properties, Type } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IWorkoutPlan } from '@onesy/api';

import { Input, Inputs, ModalForm, NoResults, View } from 'ui';
import Workout from '../Workouts/Workout';

const useStyle = style(theme => ({
  root: {

  },

  week: {
    flex: '0 0 auto',
    padding: 1,
    overflow: 'auto hidden'
  },

  day: {
    border: `1px solid ${theme.palette.light ? '#dadada' : '#575757'}`,
    height: 'unset',
    marginLeft: -1,
    marginTop: -1,
    minHeight: 300,
    minWidth: 340,
    padding: 12
  },

  workouts: {
    maxHeight: 145,
    overflow: 'hidden auto'
  },

  rest: {
    opacity: 0.4,
    cursor: 'default',
    pointerEvents: 'none'
  }
}), { name: 'onesy-Workout' });

export interface IWorkoutElement extends IBaseElement {
  object: IWorkoutPlan;
}

const Element: React.FC<IWorkoutElement> = React.forwardRef((props, ref: any) => {
  const {
    object,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();

  const [open, setOpen] = React.useState<any>({ open: false });

  const media = [object?.video, object?.image, object?.audio].filter(Boolean);

  const onOpen = React.useCallback((item: any) => {
    setOpen((previous: any) => ({
      ...previous,

      workout: item,

      open: true
    }));
  }, []);

  const onClose = React.useCallback(() => {
    setOpen((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const propertiesProps: any = {
    rowGap: 0.5,
    version: 'row',
    size: 'large'
  };

  return <>
    <Line
      ref={ref}

      gap={4}

      align='flex-start'

      justify='flex-start'

      flex

      fullWidth

      className={classNames([
        'onesy-WorkoutPlan',

        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={2.5}

        fullWidth
      >
        {!!media?.length && (
          <Medias
            align='center'

            values={media.map((item: any) => ({
              value: (item?.id || item?.url || item?.urlEmbed) ? item : undefined
            }))}

            size='large'
          />
        )}

        {object.repeat !== undefined && (
          <Properties
            values={[
              {
                name: 'Repeat',
                value: `${object.repeat || 0} times`
              }
            ]}

            {...propertiesProps}
          />
        )}

        {object.description && (
          <Type
            version='b1'

            whiteSpace='pre-wrap'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(object.description)
            }}
          />
        )}
      </Line>

      <Inputs
        gap={3}

        gapMain={2.5}

        name='Weeks'

        size='large'
      >
        {object.weeks?.map((week, index) => (
          <Input
            key={week.id}

            gap={1}

            name={`Week ${index + 1}`}

            size='large'
          >
            {week.rest && (
              <NoResults
                padding={0}

                maxWidth={340}

                size='small'
              >
                Rest week
              </NoResults>
            )}

            {!week.rest && (
              <Line
                gap={0}

                direction='row'

                align='stretch'

                fullWidth

                className={classes.week}
              >
                {week.days?.map((day, indexDay) => (
                  <Input
                    name={`Day ${indexDay + 1}`}

                    className={classNames([
                      classes.day
                    ])}

                    size='small'
                  >
                    <Line
                      gap={2}

                      fullWidth
                    >
                      {!day.rest ? <>
                        {!!day.workouts?.length && (
                          <Line
                            gap={1}

                            fullWidth

                            className={classes.workouts}
                          >
                            {day.workouts.map(workout => {
                              const palette = theme.methods.color(stringToColor(workout?.name || 'workout'));

                              return (
                                <ListItem
                                  primary={(
                                    <Type
                                      version='t2'

                                      color='default'
                                    >
                                      {workout.name}
                                    </Type>
                                  )}

                                  onClick={() => onOpen(workout)}

                                  size='small'

                                  Component='div'

                                  button

                                  noBackground

                                  style={{
                                    background: palette[theme.palette.light ? 90 : 20]
                                  }}
                                />
                              );
                            })}
                          </Line>
                        )}
                      </> : <>
                        <Line
                          align='center'

                          fullWidth
                        >
                          <NoResults
                            padding={0}

                            maxWidth={240}

                            size='small'
                          >
                            Rest day
                          </NoResults>
                        </Line>
                      </>}
                    </Line>
                  </Input>
                ))}
              </Line>
            )}
          </Input>
        ))}
      </Inputs>
    </Line>

    <View
      open={open.open}

      onClose={onClose}

      minWidth='xl'
    >
      <ModalForm
        name={open.workout?.name}

        object={object}

        onClose={onClose}
      >
        <Workout
          object={open.workout}
        />
      </ModalForm>
    </View>
  </>;
});

export default Element;
