import { Property } from '@onesy/api';

import { BaseService } from './base';

class PropertyService extends BaseService<Property> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('properties');
  }

}

export default new PropertyService();
