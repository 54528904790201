import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Tooltip, useForm, useSnackbars } from '@onesy/ui-react';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialDirectionsRun from '@onesy/icons-material-rounded-react/IconMaterialDirectionsRunW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';

import { Input, Inputs, ModalForm, Select, SelectMedia, TextField } from 'ui';
import { AppService, ExerciseService } from 'services';
import { getErrorMessage, mediaToValue, optionsEquipment, optionsForce, optionsLevel, optionsMechanic, optionsMuscles } from 'utils';
import Exercise from 'pages/main/WorkoutPlans/Exercises/Exercise';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: textToInnerHTML(object?.name),
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'instructions': {
        name: 'Instructions',
        value: object?.instructions || [],
        is: 'array',
        of: 'string'
      },
      'primary_muscles': {
        name: 'Primary muscles',
        value: object?.primary_muscles || [],
        is: 'array',
        of: 'string'
      },
      'secondary_muscles': {
        name: 'Secondary muscles',
        value: object?.secondary_muscles || [],
        is: 'array',
        of: 'string'
      },
      'force': {
        name: 'Force',
        value: object?.force,
        is: 'string'
      },
      'level': {
        name: 'Level',
        value: object?.level,
        is: 'string'
      },
      'mechanic': {
        name: 'Mechanic',
        value: object?.mechanic,
        is: 'string'
      },
      'equipment': {
        name: 'Equipment',
        value: object?.equipment || [],
        is: 'array',
        of: 'string'
      },
      'media': {
        name: 'Media',
        value: object?.media || [],
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    const result = !object?.id ? await ExerciseService.add(body) : await ExerciseService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Exercise ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onAddInstruction = React.useCallback(() => {
    const instructions = [...refs.form.current.values.instructions.value];

    instructions.push('');

    refs.form.current.onChange('instructions', instructions);
  }, []);

  const onRemoveInstruction = React.useCallback((index: number) => {
    const instructions = [...refs.form.current.values.instructions.value];

    instructions.splice(index, 1);

    refs.form.current.onChange('instructions', instructions);
  }, []);

  const selectProps: any = {
    fullWidth: true
  };

  const modal: any = {
    read: <>
      <Exercise
        object={{
          ...object,

          ...form.value
        }}
      />
    </>,

    Info: <>
      <Inputs>
        <Input
          name='Primary muscles'
        >
          <Select
            name='Muscles'

            value={form.value.primary_muscles}

            onChange={(valueNew: any) => form.onChange('primary_muscles', valueNew)}

            options={optionsMuscles}

            multiple

            {...selectProps}
          />
        </Input>

        <Input
          name='Secondary muscles'
        >
          <Select
            name='Muscles'

            value={form.value.secondary_muscles}

            onChange={(valueNew: any) => form.onChange('secondary_muscles', valueNew)}

            options={optionsMuscles}

            multiple

            {...selectProps}
          />
        </Input>

        <Input
          name='Force'
        >
          <Select
            name='Force'

            value={form.value.force}

            onChange={(valueNew: any) => form.onChange('force', valueNew)}

            options={optionsForce}

            {...selectProps}
          />
        </Input>

        <Input
          name='Level'
        >
          <Select
            name='Level'

            value={form.value.level}

            onChange={(valueNew: any) => form.onChange('level', valueNew)}

            options={optionsLevel}

            {...selectProps}
          />
        </Input>

        <Input
          name='Mechanic'
        >
          <Select
            name='Mechanic'

            value={form.value.mechanic}

            onChange={(valueNew: any) => form.onChange('mechanic', valueNew)}

            options={optionsMechanic}

            {...selectProps}
          />
        </Input>

        <Input
          name='Equipment'
        >
          <Select
            name='Equipment'

            value={form.value.equipment}

            onChange={(valueNew: any) => form.onChange('equipment', valueNew)}

            options={optionsEquipment}

            multiple

            {...selectProps}
          />
        </Input>

        <Input
          name='Instructions'

          endName={(
            <Tooltip
              name='Add step'
            >
              <IconButton
                onClick={onAddInstruction}
              >
                <IconMaterialAdd
                  size='large'
                />
              </IconButton>
            </Tooltip>
          )}
        >
          {form.values.instructions.value?.map((item: any, index: number) => (
            <Input
              key={index}

              name={`Step ${index + 1}`}

              endName={(
                <Tooltip
                  name='Remove step'
                >
                  <IconButton
                    onClick={() => onRemoveInstruction(index)}
                  >
                    <IconMaterialRemove size='large' />
                  </IconButton>
                </Tooltip>
              )}
            >
              <TextField
                key={index}

                placeholder='Instruction'

                value={textToInnerHTML(item)}

                onChange={(valueNew: any) => form.onChange('instructions', innerHTMLToText(valueNew), `${index}`)}

                minRows={3}

                maxRows={4}

                multiline

                fullWidth
              />
            </Input>
          ))}
        </Input>
      </Inputs>
    </>,

    About: <>
      <Input
        name='Name'
      >
        <TextField
          placeholder='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />
      </Input>

      <Input
        name='Images'
      >
        <SelectMedia
          value={form.value.media}

          onChange={(valueNew: any) => form.onChange('media', valueNew?.map(item => mediaToValue(item)))}

          search={{
            mime: ['image']
          }}

          multiple

          preview
        />
      </Input>
    </>
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={mode === 'update' ? [
        { name: 'Info', Icon: IconMaterialDirectionsRun },
        { name: 'About', Icon: IconMaterialInfo }
      ] : undefined}

      onChangeTab={onChangeTab}

      object={object}

      add={!object}

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      noWrite={object?.onesy}

      TabsProps={{
        justify: 'center'
      }}
    >
      {mode === 'read' ? modal.read : modal[tab]}
    </ModalForm>
  );
});

export default Element;
