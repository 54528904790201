import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { getRedirectTo } from '@onesy/utils';
import { NotFound, useSubscription } from '@onesy/ui-react';

import { getRootPage, lazy, wrapper } from 'utils';
import { MainService, AuthService } from 'services';
import { ISignedIn } from 'types';

import RootAuth from './pages/auth/Root';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import SignUpConfirm from './pages/auth/SignUpConfirm';
import ForgotPassword from './pages/auth/ForgotPassword';

import Library from './pages/main/Library/Library';
import LibraryAdd from './pages/main/Library/Add';

import SettingsInfo from './pages/main/UserSettings/Info';
import SettingsSecurity from './pages/main/UserSettings/Security';
import SettingsNotifications from './pages/main/UserSettings/Notifications';

import OrganizationSettingsInfo from './pages/main/OrganizationSettings/Info';
import OrganizationSettingsIntegrations from './pages/main/OrganizationSettings/Integrations';

import OrganizationSettingsSubscriptionsOnesy from './pages/main/OrganizationSettings/Subscription/Subscription';

const Chats = lazy(React.lazy(() => import('./pages/main/Chats/Chats')));

const RootMain = lazy(React.lazy(() => import('./pages/main/Root')));
const Bookings = lazy(React.lazy(() => import('./pages/main/Calendar/Bookings/Bookings')));
const BookingRequests = lazy(React.lazy(() => import('./pages/main/Calendar/BookingRequests/BookingRequests')));
const Available = lazy(React.lazy(() => import('./pages/main/Calendar/Available/Available')));

const Overview = lazy(React.lazy(() => import('./pages/main/Overview/Overview')));

// Finances 
const OverviewFinances = lazy(React.lazy(() => import('./pages/main/Finances/Overview/Overview')));
const AllTransactions = lazy(React.lazy(() => import('./pages/main/Finances/AllTransactions/AllTransactions')));
const Payments = lazy(React.lazy(() => import('./pages/main/Finances/Payments/Payments')));
const Charges = lazy(React.lazy(() => import('./pages/main/Finances/Charges/Charges')));
const Expenses = lazy(React.lazy(() => import('./pages/main/Finances/Expenses/Expenses')));
const Balances = lazy(React.lazy(() => import('./pages/main/Finances/Balances/Balances')));
const Invoices = lazy(React.lazy(() => import('./pages/main/Finances/Invoices/Invoices')));

// Services 
const Discounts = lazy(React.lazy(() => import('./pages/main/Services/Discounts/Discounts')));
const Sessions = lazy(React.lazy(() => import('./pages/main/Services/Sessions/Sessions')));
const Classes = lazy(React.lazy(() => import('./pages/main/Services/Classes/Classes')));
const Packages = lazy(React.lazy(() => import('./pages/main/Services/Packages/Packages')));
const Memberships = lazy(React.lazy(() => import('./pages/main/Services/Memberships/Memberships')));
const Locations = lazy(React.lazy(() => import('./pages/main/Locations/Locations')));
const Rooms = lazy(React.lazy(() => import('./pages/main/Rooms/Rooms')));
const Categories = lazy(React.lazy(() => import('./pages/main/Categories/Categories')));
const Properties = lazy(React.lazy(() => import('./pages/main/Properties/Properties')));

// Customers 
const Clients = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Clients')));
const Client = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Client')));
const ClientOverview = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Overview')));
const ClientBookings = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Bookings')));
const ClientCalendar = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Calendar')));
const ClientProgress = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Progress')));
const ClientServices = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Services')));
const ClientWorkoutPlans = lazy(React.lazy(() => import('./pages/main/Clients/Clients/WorkoutPlans')));
const ClientNutritionPlans = lazy(React.lazy(() => import('./pages/main/Clients/Clients/NutritionPlans')));
const ClientPayments = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Payments')));
const ClientCharges = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Charges')));
const ClientAssessments = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Assessments/Assessments')));
const ClientGoals = lazy(React.lazy(() => import('./pages/main/Clients/Clients/Goals/Goals')));
const ClientGroups = lazy(React.lazy(() => import('./pages/main/Clients/ClientGroups/ClientGroups')));
const Forms = lazy(React.lazy(() => import('./pages/main/Clients/Forms/Forms')));

// Users
const Users = lazy(React.lazy(() => import('./pages/main/Users/Users/Users')));
const User = lazy(React.lazy(() => import('./pages/main/Users/Users/User')));
const UserOverview = lazy(React.lazy(() => import('./pages/main/Users/Users/Overview')));
const UserCalendar = lazy(React.lazy(() => import('./pages/main/Users/Users/Calendar')));
const UserBookings = lazy(React.lazy(() => import('./pages/main/Users/Users/Bookings')));
const UserEarnings = lazy(React.lazy(() => import('./pages/main/Users/Users/Earnings')));
const UserGroups = lazy(React.lazy(() => import('./pages/main/Users/UserGroups/UserGroups')));

// My profile 
const MyProfile = lazy(React.lazy(() => import('./pages/main/MyProfile/MyProfile')));

// Nutrition plans 
const Ingredients = lazy(React.lazy(() => import('./pages/main/NutritionPlans/Ingredients/Ingredients')));
const Recipes = lazy(React.lazy(() => import('./pages/main/NutritionPlans/Recipes/Recipes')));
const NutritionPlans = lazy(React.lazy(() => import('./pages/main/NutritionPlans/NutritionPlans/NutritionPlans')));
const CaloriesCalculator = lazy(React.lazy(() => import('./pages/main/NutritionPlans/CaloriesCalculator/CaloriesCalculator')));

// Workout plans 
const Exercises = lazy(React.lazy(() => import('./pages/main/WorkoutPlans/Exercises/Exercises')));
const Workouts = lazy(React.lazy(() => import('./pages/main/WorkoutPlans/Workouts/Workouts')));
const WorkoutPlans = lazy(React.lazy(() => import('./pages/main/WorkoutPlans/WorkoutPlans/WorkoutPlans')));

// Reviews 
const Reviews = lazy(React.lazy(() => import('./pages/main/Reviews/Reviews')));

// Reports 
const Reports = lazy(React.lazy(() => import('./pages/main/Reports/Reports')));

// other 
const Boards = lazy(React.lazy(() => import('./pages/main/Boards/Boards')));
const BoardsShared = lazy(React.lazy(() => import('./pages/main/Boards/BoardsShared')));
const Board = lazy(React.lazy(() => import('./pages/main/Boards/Board')));
const Meetings = lazy(React.lazy(() => import('./pages/main/Meetings/Meetings')));
const Meeting = lazy(React.lazy(() => import('./pages/main/Meetings/Meeting')));

// medias
const MediasRoot = lazy(React.lazy(() => import('./pages/main/Library')));

// settings
const SettingsRoot = lazy(React.lazy(() => import('./pages/main/UserSettings/Root')));

// organization settings
const OrganizationSettingsRoot = lazy(React.lazy(() => import('./pages/main/OrganizationSettings/Root')));

const Routing = React.forwardRef((props: any, ref: any) => {
  const {
    ...other
  } = props;

  const navigate = useNavigate();

  const signInMethod = (value: any, setResponse: any) => {
    // Sign out
    if (refs.signedIn.current && value === null) {
      // redirect to
      const redirect_to = getRedirectTo();

      let to = `/sign-in`;

      if (AuthService.redirect) to += `?redirect_to=${redirect_to}`;

      navigate(to);
    }

    setResponse([value]);
  };

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn, signInMethod);
  const initial = useSubscription(MainService.initial);

  const refs = {
    signedIn: React.useRef(signedIn)
  };

  refs.signedIn.current = signedIn;

  // Only show routes
  // when we have all the info
  // about the auth, user
  if (!initial) return null;

  const is = signedIn?.user?.is;

  return (
    <Routes
      ref={ref}

      {...other}
    >
      {AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootMain)}
        >
          <Route
            index

            element={wrapper(Overview)}
          />

          <Route
            path='/profile'

            element={wrapper(MyProfile)}
          >
            <Route
              index

              element={wrapper(UserOverview)}
            />

            <Route
              path='calendar'

              element={wrapper(UserCalendar)}
            />

            <Route
              path='bookings'

              element={wrapper(UserBookings)}
            />

            <Route
              path='earnings'

              element={wrapper(UserEarnings)}
            />

            <Route
              path='*'

              element={<Navigate to='' />}
            />
          </Route>

          {/* Library */}
          {is?.user && (
            <Route
              path='/library'

              element={wrapper(MediasRoot)}
            >
              <Route
                index

                element={wrapper(Library)}
              />

              <Route
                path='upload'

                element={wrapper(LibraryAdd)}
              />
            </Route>
          )}

          {/* Calendar */}
          {is?.user && <>
            <Route
              path='/calendar'
            >
              <Route
                index

                element={wrapper(Bookings)}
              />

              <Route
                path='add'

                element={wrapper(Bookings)}
              />

              <Route
                path='available'

                element={wrapper(Available)}
              />

              {is?.admin && <>
                <Route
                  path='booking-requests'

                  element={wrapper(BookingRequests)}
                />

                <Route
                  path='booking-requests/:id'

                  element={wrapper(BookingRequests)}
                />
              </>}

              <Route
                path=':id'

                element={wrapper(Bookings)}
              />
            </Route>
          </>}

          {/* Clients */}
          {is?.user && <>
            <Route
              path='/clients'

              element={wrapper(Clients)}
            />

            <Route
              path='/clients/add'

              element={wrapper(Clients)}
            />

            <Route
              path='/clients/:id'

              element={wrapper(Client)}
            >
              <Route
                index

                element={wrapper(ClientOverview)}
              />

              <Route
                path='bookings'

                element={wrapper(ClientBookings)}
              />

              <Route
                path='calendar'

                element={wrapper(ClientCalendar)}
              />

              <Route
                path='progress'

                element={wrapper(ClientProgress)}
              />

              <Route
                path='services'

                element={wrapper(ClientServices)}
              />

              <Route
                path='workout-plans'

                element={wrapper(ClientWorkoutPlans)}
              />

              <Route
                path='nutrition-plans'

                element={wrapper(ClientNutritionPlans)}
              />

              <Route
                path='payments'

                element={wrapper(ClientPayments)}
              />

              <Route
                path='charges'

                element={wrapper(ClientCharges)}
              />

              <Route
                path='assessments'

                element={wrapper(ClientAssessments)}
              />

              <Route
                path='assessments/add'

                element={wrapper(ClientAssessments)}
              />

              <Route
                path='assessments/:id_assessment'

                element={wrapper(ClientAssessments)}
              />

              <Route
                path='goals'

                element={wrapper(ClientGoals)}
              />

              <Route
                path='goals/add'

                element={wrapper(ClientGoals)}
              />

              <Route
                path='goals/:id_goal'

                element={wrapper(ClientGoals)}
              />

              <Route
                path='*'

                element={<Navigate to='' />}
              />
            </Route>

            <Route
              path='/client-groups'

              element={wrapper(ClientGroups)}
            />

            <Route
              path='/client-groups/add'

              element={wrapper(ClientGroups)}
            />

            <Route
              path='/client-groups/:id'

              element={wrapper(ClientGroups)}
            />

            <Route
              path='/forms'

              element={wrapper(Forms)}
            />

            <Route
              path='/forms/add'

              element={wrapper(Forms)}
            />

            <Route
              path='/forms/:id'

              element={wrapper(Forms)}
            />
          </>}

          {/* Services */}
          {is?.user && <>
            <Route
              path='/sessions'

              element={wrapper(Sessions)}
            />

            <Route
              path='/sessions/add'

              element={wrapper(Sessions)}
            />

            <Route
              path='/sessions/:id'

              element={wrapper(Sessions)}
            />

            <Route
              path='/classes'

              element={wrapper(Classes)}
            />

            <Route
              path='/classes/add'

              element={wrapper(Classes)}
            />

            <Route
              path='/classes/:id'

              element={wrapper(Classes)}
            />

            <Route
              path='/packages'

              element={wrapper(Packages)}
            />

            <Route
              path='/packages/add'

              element={wrapper(Packages)}
            />

            <Route
              path='/packages/:id'

              element={wrapper(Packages)}
            />

            <Route
              path='/memberships'

              element={wrapper(Memberships)}
            />

            <Route
              path='/memberships/add'

              element={wrapper(Memberships)}
            />

            <Route
              path='/memberships/:id'

              element={wrapper(Memberships)}
            />

            <Route
              path='/locations'

              element={wrapper(Locations)}
            />

            <Route
              path='/locations/add'

              element={wrapper(Locations)}
            />

            <Route
              path='/locations/:id'

              element={wrapper(Locations)}
            />

            <Route
              path='/properties'

              element={wrapper(Properties)}
            />

            <Route
              path='/properties/add'

              element={wrapper(Properties)}
            />

            <Route
              path='/discounts'

              element={wrapper(Discounts)}
            />

            <Route
              path='/discounts/add'

              element={wrapper(Discounts)}
            />

            <Route
              path='/rooms'

              element={wrapper(Rooms)}
            />

            <Route
              path='/categories'

              element={wrapper(Categories)}
            />
          </>}

          {/* Nutrition plans */}
          {is?.user && <>
            <Route
              path='/ingredients'

              element={wrapper(Ingredients)}
            />

            <Route
              path='/ingredients/add'

              element={wrapper(Ingredients)}
            />

            <Route
              path='/ingredients/:id'

              element={wrapper(Ingredients)}
            />

            <Route
              path='calories-calculator'

              element={wrapper(CaloriesCalculator)}
            />

            <Route
              path='/recipes'

              element={wrapper(Recipes)}
            />

            <Route
              path='/recipes/add'

              element={wrapper(Recipes)}
            />

            <Route
              path='/recipes/:id'

              element={wrapper(Recipes)}
            />

            <Route
              path='/nutrition-plans'

              element={wrapper(NutritionPlans)}
            />

            <Route
              path='/nutrition-plans/add'

              element={wrapper(NutritionPlans)}
            />

            <Route
              path='/nutrition-plans/:id'

              element={wrapper(NutritionPlans)}
            />
          </>}

          {/* Workout plans */}
          {is?.user && <>
            <Route
              path='/exercises'

              element={wrapper(Exercises)}
            />

            <Route
              path='/exercises/add'

              element={wrapper(Exercises)}
            />

            <Route
              path='/exercises/:id'

              element={wrapper(Exercises)}
            />

            <Route
              path='/workouts'

              element={wrapper(Workouts)}
            />

            <Route
              path='/workouts/add'

              element={wrapper(Workouts)}
            />

            <Route
              path='/workouts/:id'

              element={wrapper(Workouts)}
            />

            <Route
              path='/workout-plans'

              element={wrapper(WorkoutPlans)}
            />

            <Route
              path='/workout-plans/add'

              element={wrapper(WorkoutPlans)}
            />

            <Route
              path='/workout-plans/:id'

              element={wrapper(WorkoutPlans)}
            />
          </>}

          {/* Finances */}
          {is?.admin && <>
            <Route
              path='/finances'

              element={wrapper(OverviewFinances)}
            />

            <Route
              path='/transactions'

              element={wrapper(AllTransactions)}
            />

            <Route
              path='/payments'

              element={wrapper(Payments)}
            />

            <Route
              path='/payments/add'

              element={wrapper(Payments)}
            />

            <Route
              path='/charges'

              element={wrapper(Charges)}
            />

            <Route
              path='/charges/add'

              element={wrapper(Charges)}
            />

            <Route
              path='/expenses'

              element={wrapper(Expenses)}
            />

            <Route
              path='/expenses/add'

              element={wrapper(Expenses)}
            />

            <Route
              path='/balances'

              element={wrapper(Balances)}
            />

            <Route
              path='/invoices'

              element={wrapper(Invoices)}
            />

            <Route
              path='/invoices/add'

              element={wrapper(Invoices)}
            />
          </>}

          {/* Reviews */}
          {is?.user && <>
            <Route
              path='/reviews'

              element={wrapper(Reviews)}
            />

            <Route
              path='/reviews/:id'

              element={wrapper(Reviews)}
            />
          </>}

          {/* Reports */}
          {is?.admin && <>
            <Route
              path='/reports'

              element={wrapper(Reports)}
            />
          </>}

          {/* Boards */}
          {is?.user && <>
            <Route
              path='/boards'

              element={wrapper(Boards)}
            />

            <Route
              path='/boards/shared'

              element={wrapper(BoardsShared)}
            />

            <Route
              path='/boards/shared/:id'

              element={wrapper(Board)}
            />

            <Route
              path='/boards/:id'

              element={wrapper(Board)}
            />
          </>}

          {/* Meetings */}
          {is?.user && <>
            <Route
              path='/meetings'

              element={wrapper(Meetings)}
            />

            <Route
              path='/meetings/:id'

              element={wrapper(Meeting)}
            />
          </>}

          {/* Employees */}
          {is?.admin && <>
            <Route
              path='/employees'

              element={wrapper(Users)}
            />

            <Route
              path='/employees/add'

              element={wrapper(Users)}
            />

            <Route
              path='/employees/:id'

              element={wrapper(User)}
            >
              <Route
                index

                element={wrapper(UserOverview)}
              />

              <Route
                path='calendar'

                element={wrapper(UserCalendar)}
              />

              <Route
                path='bookings'

                element={wrapper(UserBookings)}
              />

              <Route
                path='earnings'

                element={wrapper(UserEarnings)}
              />

              <Route
                path='*'

                element={<Navigate to='' />}
              />
            </Route>

            <Route
              path='/employee-groups'

              element={wrapper(UserGroups)}
            />

            <Route
              path='/employee-groups/add'

              element={wrapper(UserGroups)}
            />

            <Route
              path='/employee-groups/:id'

              element={wrapper(UserGroups)}
            />
          </>}

          {/* Chats */}
          {is?.user && <>
            <Route
              path='/chats'

              element={wrapper(Chats)}
            />

            <Route
              path='/chats/new'

              element={wrapper(Chats)}
            />

            <Route
              path='/chats/:id'

              element={wrapper(Chats)}
            />
          </>}

          {/* Settings */}
          <Route
            element={wrapper(SettingsRoot)}
          >
            <Route
              path='/settings'

              element={wrapper(SettingsInfo)}
            />

            <Route
              path='/settings/security'

              element={wrapper(SettingsSecurity)}
            />

            <Route
              path='/settings/notifications'

              element={wrapper(SettingsNotifications)}
            />
          </Route>

          {/* Organization settings */}
          {is?.admin && (
            <Route
              element={wrapper(OrganizationSettingsRoot)}
            >
              <Route
                path='/organization/settings/info'

                element={wrapper(OrganizationSettingsInfo)}
              />

              <Route
                path='/organization/settings/subscription'

                element={wrapper(OrganizationSettingsSubscriptionsOnesy)}
              />

              <Route
                path='/organization/settings/integrations'

                element={wrapper(OrganizationSettingsIntegrations)}
              />
            </Route>
          )}

          <Route
            path='*'

            element={(
              <NotFound
                to={getRootPage(signedIn)}

                ButtonProps={{
                  name: 'Back to home'
                }}
              />
            )}
          />
        </Route>
      )}

      {!AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootAuth)}
        >
          <Route
            path='/sign-in'

            element={wrapper(SignIn)}
          />

          <Route
            path='/sign-up'

            element={wrapper(SignUp)}
          />

          <Route
            path='/sign-up/confirm'

            element={wrapper(SignUpConfirm)}
          />

          <Route
            path='/forgot-password'

            element={wrapper(ForgotPassword)}
          />

          <Route
            path='*'

            element={<Navigate to='/sign-in' />}
          />
        </Route>
      )}
    </Routes>
  );
});

export default Routing;
