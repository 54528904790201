import React from 'react';

import { is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, SmartTextField, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { useOnesyTheme } from '@onesy/style-react';

import IconMaterialRefresh from '@onesy/icons-material-rounded-react/IconMaterialRefreshW100';

import { AutoCompleteCurrency, Button, Divider, Input, Inputs, LocationForm, Palette, Paper, PreviewTheme, SelectMedia } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { getErrorMessage, getMediaUrl, mediaToValue } from 'utils';
import { ISignedIn } from 'types';

const OrganizationSettingsInfo = React.forwardRef(() => {
  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const object = signedIn?.organization;

  const form = useForm({
    values: {
      'name': {
        name: 'Organization name',
        value: object?.name,
        is: 'string',
        min: 2,
        max: 1400,
        messages: {
          min: 'Organization name must be min 2 characters',
          max: 'Organization name can be max 1400 characters'
        },
        required: true,
      },
      'properties': {
        name: 'Properties',
        is: 'object',
        value: object?.properties || {}
      },
      'location': {
        name: 'Location',
        is: 'object',
        value: object?.location ? {
          ...object?.location,

          country: is('object', object?.location?.country) ? object?.location.country : {
            name: object?.location?.country || ''
          }
        } : {}
      },
      'coordinates': {
        name: 'Coordinates',
        is: 'string',
        value: object?.location?.coordinates?.join(', ')
      },

      'settings.logos.logo_light': {
        name: 'Logo light',
        value: object?.settings?.logos?.logo_light
      },
      'settings.logos.favicon_light': {
        name: 'Favicon light',
        value: object?.settings?.logos?.favicon_light
      },

      'settings.theme.palette.image': {
        name: 'Theme image',
        value: object?.settings?.theme?.palette?.image
      },

      'settings.theme.palette.color': {
        name: 'Theme colors',
        is: 'object',
        value: object?.settings?.theme?.palette?.color || {}
      },

      'settings.currency': {
        name: 'Settings currency',
        value: object?.settings?.currency || '',
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onUpdate = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('info');

    const body = {
      ...refs.form.current.value
    };

    body.location.coordinates = body.coordinates ? body.coordinates.split(',').filter(Boolean).map((item: any) => Number(item.trim())) : [];

    delete body.coordinates;

    const result = await OrganizationService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Organization updated'
      });

      // refresh organization
      // in the entire app
      await AuthService.me();
    }

    setLoading(false);
  }, [object, form]);

  const onChangePalette = React.useCallback((valueNew: any) => {
    refs.form.current.onChange([
      ['settings.theme.palette.image', mediaToValue(valueNew.image)],
      ['settings.theme.palette.color', valueNew.primary, 'primary.main'],
      ['settings.theme.palette.color', valueNew.secondary, 'secondary.main'],
      ['settings.theme.palette.color', valueNew.tertiary, 'tertiary.main'],
      ['settings.theme.palette.color', valueNew.quaternary, 'quaternary.main']
    ]);
  }, []);

  const onResetPalette = React.useCallback(() => {
    refs.form.current.onChange([
      ['settings.theme.palette.color', {}]
    ]);
  }, []);

  return (
    <Paper
      name='Profile'

      midFooter={(
        <Button
          onClick={onUpdate}

          size='large'

          disabled={loading}
        >
          Update
        </Button>
      )}
    >
      <Input
        name='Organization name'
      >
        <SmartTextField
          name='Name'

          valueDefault={textToInnerHTML(object?.name || '')}

          error={!!form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: string) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          additional={{
            version: 'h2',
            align: 'center',
            style: {
              width: 'auto'
            }
          }}
        />
      </Input>

      <Divider />

      <Inputs
        name='Branding'

        description='Logo, colors'
      >
        <Line
          gap={2}

          fullWidth
        >
          <Line
            gap={2}

            direction={{
              default: 'row',
              700: 'column'
            }}

            fullWidth
          >
            <Line
              gap={1.5}
            >
              <Type
                version='t2'
              >
                Logo
              </Type>

              <SelectMedia
                value={form.values['settings.logos.logo_light'].value}

                onChange={(valueNew: any) => form.onChange('settings.logos.logo_light', mediaToValue(valueNew))}
              />
            </Line>

            <PreviewTheme
              version={`${theme.palette.light ? 'light' : 'dark'}-preview`}

              url={getMediaUrl(form.values['settings.logos.logo_light'].value)}

              example='logo'
            />
          </Line>

          <Line
            gap={2}

            direction={{
              default: 'row',
              700: 'column'
            }}

            fullWidth
          >
            <Line
              gap={1.5}
            >
              <Type
                version='t2'
              >
                Favicon
              </Type>

              <SelectMedia
                value={form.values['settings.logos.favicon_light'].value}

                onChange={(valueNew: any) => form.onChange('settings.logos.favicon_light', mediaToValue(valueNew))}
              />
            </Line>

            <PreviewTheme
              version={`${theme.palette.light ? 'light' : 'dark'}-preview`}

              url={getMediaUrl(form.values['settings.logos.favicon_light'].value)}

              example='favicon'
            />
          </Line>
        </Line>

        <Input
          name='Colors'

          endName={(
            <Tooltip
              name='Reset palette'
            >
              <IconButton
                onClick={onResetPalette}
              >
                <IconMaterialRefresh />
              </IconButton>
            </Tooltip>
          )}
        >
          <Palette
            value={{
              image: form.values[`settings.theme.palette.image`].value,
              primary: form.values[`settings.theme.palette.color`].value?.primary?.main || '',
              secondary: form.values[`settings.theme.palette.color`].value?.secondary?.main || '',
              tertiary: form.values[`settings.theme.palette.color`].value?.tertiary?.main || '',
              quaternary: form.values[`settings.theme.palette.color`].value?.quaternary?.main || ''
            }}

            onChange={onChangePalette}
          />
        </Input>
      </Inputs>

      <Divider />

      <Inputs
        name='Additional info'
      >
        <Input
          name='Currency'
        >
          <AutoCompleteCurrency
            value={form.values['settings.currency'].value}

            onChange={(valueNew: any) => form.onChange('settings.currency', valueNew?.value)}

            error={!!form.values['settings.currency'].error}

            helperText={form.values['settings.currency'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Location'
        >
          <LocationForm
            form={form}

            fullWidth
          />
        </Input>
      </Inputs>
    </Paper>
  );
});

export default OrganizationSettingsInfo;
