import React from 'react';

import { capitalize, cleanValue, copy, getID, getObjectValue, is } from '@onesy/utils';
import { IconButton, Label, Line, Switch, Tooltip, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { style } from '@onesy/style-react';
import { OnesyDate, add } from '@onesy/date';

import IconMaterialContentCopy from '@onesy/icons-material-rounded-react/IconMaterialContentCopyW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, DatePicker, DateTimeRangePicker, Input, Inputs, ModalForm, Select, TimeRangePicker } from 'ui';
import { AppService, AvailableService, OrganizationService } from 'services';
import { getDate, getErrorMessage, optionsDuration } from 'utils';
import { Available } from '@onesy/api';

const useStyle = style(theme => ({
  root: {

  },

  input: {
    '& .onesy-TextField-wrapper, & .onesy-TextField-root': {
      height: 'auto !important',
      width: 'auto !important'
    },

    '& .onesy-TextField-input': {
      ...theme.typography.values.d3,

      fontSize: '2.1rem !important',
      fontWeight: '700 !important'
    }
  }
}), { name: 'onesy-Available' });

const Element = React.forwardRef((props: any) => {
  const {
    object: objectProps,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(objectProps);
  const [tab, setTab] = React.useState('Week');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'times': {
        name: 'Times',
        is: 'object',
        value: object?.times || {
          weekly: {
            active: true,
            days: {
              1: { active: true },
              2: { active: true },
              3: { active: true },
              4: { active: true },
              5: { active: true },
              6: { active: false },
              7: { active: false }
            }
          },
          dates: {
            active: true
          }
        }
      },
      'employees': {
        name: 'Employees',
        value: object?.employees || [],
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: Available = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (is('array', body.employees)) body.employees = body.employees.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    Object.values(body.times.weekly.days || {}).forEach(itemDay => {

      itemDay.values?.forEach((item: any) => {
        delete item.object;
      });
    });

    body.times.dates.values?.forEach((item: any) => {
      delete item.object;
    });

    const result = !object?.id ? await AvailableService.add(body) : await AvailableService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Available ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTab = React.useCallback(async (value: any) => {
    setTab(value);
  }, []);

  const onStopPropagation = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const valueTimeDefaultObject = React.useMemo(() => {
    return {
      status: 'working'
    }
  }, []);

  const onAddDate = React.useCallback(() => {
    const values = [...(refs.form.current.values.times.value?.dates?.values || [])];

    values.push({
      id: getID(),
      from: OnesyDate.milliseconds,
      to: add(1, 'hour', OnesyDate.onesyDate).milliseconds,

      ...valueTimeDefaultObject
    });

    refs.form.current.onChange('times', values, `dates.values`);
  }, []);

  const onRemoveDate = React.useCallback((index: number) => {
    const values = [...(refs.form.current.values.times.value?.dates?.values || [])];

    if (index > -1) values.splice(index, 1);

    refs.form.current.onChange('times', values, `dates.values`);
  }, []);

  const onAddDay = React.useCallback((path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    values.push({
      id: getID(),
      from: OnesyDate.milliseconds,
      to: add(1, 'hour', OnesyDate.onesyDate).milliseconds,

      ...valueTimeDefaultObject
    });

    refs.form.current.onChange('times', values, `weekly.days.${path}.values`);
  }, []);

  const onRemoveDay = React.useCallback((index: number, path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    if (index > -1) values.splice(index, 1);

    refs.form.current.onChange('times', values, `weekly.days.${path}.values`);
  }, []);

  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const onCopyTimes = React.useCallback((path: string) => {
    const values = [...(getObjectValue(refs.form.current.values.times.value, `weekly.days.${path}.values`) || [])];

    const times = copy(refs.form.current.values.times.value);

    Object.values(times.weekly.days).forEach((day: any) => {
      day.values = values.map(item => ({
        ...item,

        id: getID()
      }));
    });

    refs.form.current.onChange('times', times);
  }, []);

  const optionsStatus = React.useMemo(() => {
    return [
      { name: 'Working', value: 'working' },
      { name: 'Not working', value: 'not-working' },
      { name: 'On a break', value: 'break' },
      { name: 'Scheduled', value: 'pending' },
      { name: 'Rescheduled', value: 'rescheduled' },
      { name: 'Cancelled', value: 'canceled' },
      { name: 'Other', value: 'other' }
    ]
  }, []);

  const optionsEntire = React.useMemo(() => {
    return [
      { name: 'None', value: null },

      ...optionsDuration
    ]
  }, []);

  const itemToText = React.useCallback((item: any) => {
    if (item === 'pending') return 'scheduled';

    if (item === 'not-count-workout-session') return `don't count workout session`;

    return item;
  }, []);

  const getTimeValue = (item: any, index: number, property: string, path: string) => {
    const pathMain = `${property}.${path}.${index}`;

    const weekly = property === 'weekly';

    let ElementDate = weekly ? TimeRangePicker : DateTimeRangePicker;

    const additional = [item.entire && `entire ${item.entire}`, item.status && cleanValue(itemToText(item.status), { capitalize: false }), item.action && cleanValue(itemToText(item.action), { capitalize: false })].filter(Boolean);

    return (
      <Input
        key={index + pathMain}

        name={(
          <Type
            version='b1'
          >
            <b>{item.from ? `${getDate(item.from, weekly ? 'time' : 'entire')} — ${getDate(item.to, weekly ? 'time' : 'entire')}` : 'No time selected'}</b>

            {additional.length ? ` - ${additional.join(', ')}` : ''}
          </Type>
        )}

        endName={(
          <Tooltip
            name='Remove date'
          >
            <IconButton
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                weekly ? onRemoveDay(index, path.replace('days.', '').replace('.values', '')) : onRemoveDate(index);
              }}
            >
              <IconMaterialRemove
                {...iconProps}
              />
            </IconButton>
          </Tooltip>
        )}

        size='large'

        expand
      >
        <ElementDate
          name='Time'

          value={[item.from, item.to].filter(Boolean).map(item => new OnesyDate(item)) as any}

          onChange={(valueNew: any) => {
            const from = valueNew?.[0]?.milliseconds;
            const to = valueNew?.[1]?.milliseconds;

            form.onChange([
              ['times', from, `${pathMain}.from`],
              ['times', to, `${pathMain}.to`]
            ]);
          }}

          TooltipProps={{
            switch: true
          }}

          MainProps={{
            className: classes.input
          }}

          fullWidth
        />

        <Input
          name='Status'

          description='What is happening at this time'
        >
          <Select
            valueDefault={item.status || null}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `${pathMain}.status`)}

            options={optionsStatus}

            fullWidth
          />
        </Input>

        {!weekly && <>
          <Input
            name='Entire'

            description='You can mark an entire day, month etc. as ie. working'
          >
            <Select
              valueDefault={item.entire || null}

              onChange={(valueNew: any) => form.onChange('times', valueNew, `${pathMain}.entire`)}

              options={optionsEntire}

              fullWidth
            />
          </Input>
        </>}
      </Input>
    );
  };

  const iconProps: any = {
    size: 'large'
  };

  const modal: any = {
    'Week': <>
      <Inputs
        name='Weekly'

        description='Weekly repeating pattern of work times'

        startName={(
          <Label
            checked={!!form.values.times.value?.weekly?.active}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `weekly.active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        divider
      >
        {days.map((day: string, index: number) => {
          const itemDay = form.values.times.value?.weekly?.days?.[index + 1];

          return (
            <Input
              key={day}

              name={`${capitalize(day)}${itemDay?.values?.length ? ` ( ${itemDay.values.length} )` : ''}`}

              startName={(
                <Label
                  checkedDefault={itemDay?.active}

                  onChange={(valueNew: any) => form.onChange('times', valueNew, `weekly.days.${index + 1}.active`)}

                  onClick={onStopPropagation}

                  size='small'
                >
                  <Switch />
                </Label>
              )}

              endName={<>
                <Tooltip
                  name='Copy to other days'
                >
                  <IconButton
                    onClick={(event: MouseEvent) => {
                      event.stopPropagation();

                      onCopyTimes(`${index + 1}`)
                    }}
                  >
                    <IconMaterialContentCopy
                      {...iconProps}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  name='Add time'
                >
                  <IconButton
                    onClick={(event: MouseEvent) => {
                      event.stopPropagation();

                      onAddDay(`${index + 1}`)
                    }}
                  >
                    <IconMaterialAdd
                      {...iconProps}
                    />
                  </IconButton>
                </Tooltip>
              </>}
            >
              {!!itemDay?.values?.length && (
                <Line
                  gap={0}

                  fullWidth
                >
                  {(itemDay?.values || []).map((item: any, index_: number) => getTimeValue(item, index_, 'weekly', `days.${index + 1}.values`))}
                </Line>
              )}

              <Input
                description='Which day onward to end the pattern, optionally'
              >
                <DatePicker
                  name='Ends at'

                  valueDefault={itemDay.ends_at ? new OnesyDate(itemDay.ends_at) : undefined}

                  onChange={((valueNew: OnesyDate) => form.onChange('times', valueNew ? valueNew.milliseconds : null, `weekly.days.${index + 1}.ends_at`)) as any}

                  fullWidth
                />
              </Input>
            </Input>
          );
        })}
      </Inputs>
    </>,

    'Dates': <>
      <Input
        name={`Dates${form.values.times.value?.dates?.values?.length ? ` ( ${form.values.times.value.dates.values.length} )` : ''}`}

        description='Specific times of working or not working'

        startName={(
          <Label
            checked={!!form.values.times.value?.dates?.active}

            onChange={(valueNew: any) => form.onChange('times', valueNew, `dates.active`)}

            onClick={onStopPropagation}
          >
            <Switch />
          </Label>
        )}

        endName={(
          <Tooltip
            name='Add date'
          >
            <IconButton
              onClick={(event: MouseEvent) => {
                event.stopPropagation();

                onAddDate();
              }}
            >
              <IconMaterialAdd {...iconProps} />
            </IconButton>
          </Tooltip>
        )}
      >
        <Line
          gap={0}

          fullWidth
        >
          {(form.values.times.value?.dates?.values || []).map((item: any, index: number) => getTimeValue(item, index, 'dates', `values`))}
        </Line>
      </Input>
    </>,

    'Employees': (
      <Inputs>
        <Input
          name='Employees'

          description='Available times are for these employees'
        >
          <AutoCompleteObjects
            name='Employees'

            value={form.values.employees.value}

            onChange={(valueNew: any) => form.onChange('employees', valueNew)}

            service={OrganizationService}

            method='queryUsersPost'

            multiple

            fullWidth
          />
        </Input>
      </Inputs>
    )
  };

  return (
    <ModalForm
      object={object}

      add

      tabDefault='Week'

      tab={tab}

      tabs={['Week', 'Dates', 'Employees']}

      onChangeTab={onChangeTab}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;