import React from 'react';

import { arrayMoveItem, copy, getID, innerHTMLToText, is, stringToColor, textToInnerHTML } from '@onesy/utils';
import { IconButton, Label, Line, Switch, Tooltip, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialSubscriptions from '@onesy/icons-material-rounded-react/IconMaterialSubscriptionsW100';
import IconMaterialKeyboardArrowUp from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowUpW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialContentCopy from '@onesy/icons-material-rounded-react/IconMaterialContentCopyW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, Color, Input, Inputs, MediaForm, ModalForm, NoResults, NumericTextField, SelectAColor, TextField } from 'ui';
import { AppService, WorkoutPlanService, WorkoutService } from 'services';
import { getErrorMessage } from 'utils';
import WorkoutPlan from 'pages/main/WorkoutPlans/WorkoutPlans/WorkoutPlan';

const useStyle = style(theme => ({
  root: {

  },

  week: {
    flex: '0 0 auto',
    padding: 1,
    overflow: 'auto hidden'
  },

  day: {
    border: `1px solid ${theme.palette.light ? '#dadada' : '#575757'}`,
    height: 'unset',
    marginLeft: -1,
    marginTop: -1,
    minHeight: 300,
    minWidth: 340,
    padding: 12
  },

  workouts: {
    maxHeight: 145,
    overflow: 'hidden auto'
  }
}), { name: 'onesy-WorkoutPlan' });

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'weeks': {
        name: 'Weeks',
        value: object?.weeks || [],
        is: 'array',
        of: 'object'
      },
      'repeat': {
        name: 'Repeat',
        value: object?.repeat,
        is: 'number'
      },
      'color': {
        name: 'Color',
        value: object?.color,
        is: 'string'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (body.weeks) body.weeks = body.weeks.map(item => {
      if (item.days) {
        item.days.forEach(day => {
          if (is('array', day.workouts)) day.workouts = day.workouts.map(itemWorkout => ({
            id: itemWorkout.id,
            name: textToInnerHTML(itemWorkout.name)
          }));
        });
      }

      return item;
    });

    const result = !object?.id ? await WorkoutPlanService.add(body) : await WorkoutPlanService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Workout plan ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onAddWeek = React.useCallback(() => {
    const weeks = [...refs.form.current.values.weeks.value];

    weeks.push({
      id: getID(),
      rest: false,
      days: new Array(7).fill(1).map(() => ({ id: getID(), rest: false, workouts: [] }))
    });

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onRemoveWeek = React.useCallback((index: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    weeks.splice(index, 1);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onRemoveWorkout = React.useCallback((index: number, indexDay: number, workout: any) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const day = weeks[index].days[indexDay];

    const indexWorkout = day.workouts.findIndex(itemWorkout => itemWorkout.id === workout.id);

    if (indexWorkout > -1) day.workouts.splice(indexWorkout, 1);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onCopyWeek = React.useCallback((index: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const week = copy(weeks[index]);

    week.id = getID();

    week.days.forEach(day => {
      day.id = getID();
    });

    weeks.push(week);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onMoveWeek = React.useCallback((index: number, indexNew: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    arrayMoveItem(weeks, index, indexNew);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const modal: any = {
    read: <>
      <WorkoutPlan
        object={{
          ...object,

          ...form.value
        }}
      />
    </>,

    Info: <>
      <Inputs>
        <Input
          name='Repeat'

          description='How many times to repeat it'
        >
          <NumericTextField
            placeholder='Repeat'

            valueDefault={form.values.repeat.value}

            onChange={(valueNew: any) => form.onChange('repeat', valueNew)}

            min={0}

            fullWidth
          />
        </Input>

        <Inputs
          gap={3}

          gapMain={2.5}

          name='Weeks'

          size='large'

          endName={(
            <Tooltip
              name='Add week'
            >
              <IconButton
                onClick={onAddWeek}
              >
                <IconMaterialAdd
                  size='large'
                />
              </IconButton>
            </Tooltip>
          )}
        >
          {form.values.weeks.value?.map((week, index) => (
            <Input
              key={week.id}

              gap={1}

              name={`Week ${index + 1}`}

              endName={[
                <Label
                  value={week.rest}

                  onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.rest`)}
                >
                  <Switch />

                  <Type
                    version='b1'
                  >
                    Rest week
                  </Type>
                </Label>,

                <Tooltip
                  name='Move up'
                >
                  <IconButton
                    onClick={() => onMoveWeek(index, index - 1)}

                    disabled={!index}
                  >
                    <IconMaterialKeyboardArrowUp
                      size='large'
                    />
                  </IconButton>
                </Tooltip>,

                <Tooltip
                  name='Move down'
                >
                  <IconButton
                    onClick={() => onMoveWeek(index, index + 1)}

                    disabled={index >= (form.value.weeks?.length - 1)}
                  >
                    <IconMaterialKeyboardArrowDown
                      size='large'
                    />
                  </IconButton>
                </Tooltip>,

                <Tooltip
                  name='Copy week'
                >
                  <IconButton
                    onClick={() => onCopyWeek(index)}
                  >
                    <IconMaterialContentCopy
                      size='large'
                    />
                  </IconButton>
                </Tooltip>,

                <Tooltip
                  name='Remove week'
                >
                  <IconButton
                    onClick={() => onRemoveWeek(index)}
                  >
                    <IconMaterialRemove
                      size='large'
                    />
                  </IconButton>
                </Tooltip>
              ]}

              size='large'
            >
              {week.rest && (
                <NoResults
                  padding={0}

                  maxWidth={340}
                >
                  Rest week
                </NoResults>
              )}

              {!week.rest && (
                <Line
                  gap={0}

                  direction='row'

                  align='stretch'

                  fullWidth

                  className={classes.week}
                >
                  {week.days?.map((day, indexDay) => (
                    <Input
                      name={`Day ${indexDay + 1}`}

                      endName={(
                        <Label
                          value={day.rest}

                          onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.days.${indexDay}.rest`)}

                          position='top'

                          size='small'
                        >
                          <Switch />

                          <Type
                            version='b3'
                          >
                            Rest day
                          </Type>
                        </Label>
                      )}

                      NameProps={{
                        align: 'flex-start'
                      }}

                      className={classNames([
                        classes.day
                      ])}

                      size='small'
                    >
                      <Line
                        gap={2}

                        fullWidth
                      >
                        {!day.rest ? <>
                          <AutoCompleteObjects
                            name='Workouts'

                            placeholder='Workout'

                            value={day.workouts}

                            onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.days.${indexDay}.workouts`)}

                            service={WorkoutService}

                            query={{
                              onesy: true
                            }}

                            multiple

                            fullWidth

                            noInputValue
                          />

                          {!!day.workouts?.length && (
                            <Line
                              gap={1}

                              fullWidth

                              className={classes.workouts}
                            >
                              {day.workouts.map(workout => {
                                const palette = theme.methods.color(stringToColor(workout?.name || 'workout'));

                                return (
                                  <Input
                                    name={workout.name}

                                    endName={(
                                      <Tooltip
                                        name='Remove workout'
                                      >
                                        <IconButton
                                          onClick={() => onRemoveWorkout(index, indexDay, workout)}
                                        >
                                          <IconMaterialRemove
                                            size='regular'
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}

                                    size='small'

                                    style={{
                                      padding: '4px 12px',
                                      background: palette[theme.palette.light ? 90 : 20]
                                    }}
                                  />
                                );
                              })}
                            </Line>
                          )}
                        </> : <>
                          <Line
                            align='center'

                            fullWidth
                          >
                            <NoResults
                              padding={0}

                              maxWidth={240}

                              size='small'
                            >
                              Rest day
                            </NoResults>
                          </Line>
                        </>}
                      </Line>
                    </Input>
                  ))}
                </Line>
              )}
            </Input>
          ))}
        </Inputs>
      </Inputs>
    </>,

    About: <>
      <Input
        name='Name'
      >
        <TextField
          placeholder='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />
      </Input>

      <Input
        name='Description'
      >
        <TextField
          placeholder='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={3}

          maxRows={4}

          multiline

          fullWidth
        />
      </Input>

      <MediaForm
        type='image'

        name='Image'

        form={form}
      />

      <MediaForm
        type='audio'

        name='Audio'

        form={form}
      />

      <MediaForm
        type='video'

        name='Video'

        form={form}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={mode === 'update' ? [
        { name: 'Info', Icon: IconMaterialSubscriptions },
        { name: 'About', Icon: IconMaterialInfo }
      ] : undefined}

      onChangeTab={onChangeTab}

      footerRightStart={mode === 'update' ? <>
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      </> : (
        <Color
          color={form.value.color}
        />
      )}

      object={object}

      add={!object}

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {mode === 'read' ? modal.read : modal[tab]}
    </ModalForm>
  );
});

export default Element;
