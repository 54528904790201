import React from 'react';

import { stringToColor } from '@onesy/utils';
import { Chip, Chips } from '@onesy/ui-react';
import { classNames, colors, style, useOnesyTheme } from '@onesy/style-react';
import { ICategory } from '@onesy/api';

import { CustomerService } from 'services';

const useStyle = style(theme => ({
  root: {

  },

  root_items: {
    '&.onesy-Chips-root': {

    }
  },

  fullWidth: {
    '&.onesy-Chips-root': {
      width: '100%'
    }
  }
}), { name: 'onesy-Categories' });

const Categories = React.forwardRef((props: any, ref: any) => {
  const {
    property = 'categories',

    service,

    items,

    fullWidth,

    className,

    children,

    ...other
  } = props;

  const theme = useOnesyTheme();

  const { classes } = useStyle();

  const onClickTag = React.useCallback((item: ICategory) => {
    if (service) {
      (service as typeof CustomerService).search.emit({
        [property]: [
          {
            ...item,

            value: item.id
          }
        ]
      });
    }
  }, [service, property]);

  const categories = React.useMemo(() => {
    return (items || []).map((item: any) => {
      const palette = theme.methods.color(stringToColor(item.name));

      item.palette = palette;
      item.color = palette[50];

      return item;
    });
  }, [items, theme]);

  return (
    <Chips
      ref={ref}

      gap={0.75}

      total={items?.length}

      max={3}

      justify='flex-end'

      wrap='wrap'

      size='small'

      AdditionalChipProps={{
        color: 'default'
      }}

      className={classNames([
        className,
        classes.root,
        fullWidth && classes.fullWidth,
        !!items?.length && !other.fullWidth && classes.root_items
      ])}

      {...other}
    >
      {categories.map((item: ICategory) => {
        const palette = theme.methods.color(colors[item.color || 'lightblue']?.[500] || stringToColor(item.name) || '#00000');

        const color = palette[theme.palette.light ? 40 : 70];

        return (
          <Chip
            onClick={() => onClickTag(item)}

            style={{
              color,
              boxShadow: `${color} 0px 0px 0px 1px inset`
            }}
          >
            {item.name}
          </Chip>
        );
      })}
    </Chips>
  );
});

export default Categories;