import React from 'react';

import { is } from '@onesy/utils';
import { useForm, useSnackbars } from '@onesy/ui-react';

import { Input, Inputs, ModalForm, Select, TextField } from 'ui';
import { AppService, PropertyService } from 'services';
import { getErrorMessage, optionsPropertyFor, optionsPropertyType } from 'utils';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      name: {
        name: 'Name',
        value: object?.name,
        is: 'string'
      },
      type: {
        name: 'Type',
        value: object?.type || 'number',
        is: 'string'
      },
      for: {
        name: 'For',
        value: object?.for || 'client',
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      model: 'bookings',

      apps: ['personal-trainer']
    };

    const result = await PropertyService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Property added`
      });

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  return (
    <ModalForm
      ref={ref}

      {...props}

      name='Add property'

      add

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    >
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Type'

          description={`If the type is 'number', you may see it compared within a chart, if it's 'text' it's only shown historically what was the value, and when.`}
        >
          <Select
            name='Type'

            value={form.value.type}

            onChange={(valueNew: any) => form.onChange('type', valueNew)}

            options={optionsPropertyType}

            error={form.values['type'].error}

            helperText={form.values['type'].error}

            fullWidth
          />
        </Input>

        <Input
          name='For'

          description={`If it's 'general', the custom property is populated for the entire booking for example, if it's 'client', inside the booking you fill the field per client.`}
        >
          <Select
            name='For'

            value={form.value.for}

            onChange={(valueNew: any) => form.onChange('for', valueNew)}

            options={optionsPropertyFor}

            error={form.values['for'].error}

            helperText={form.values['for'].error}

            fullWidth
          />
        </Input>
      </Inputs>
    </ModalForm>
  );
});

export default Element;
